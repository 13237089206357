<template>
  <div>
    <div v-if="hgzTopTen.length > 0">
      <div v-for="(item, idx) in hgzTopTen" :key="idx" class="prints-list">
        <div class="title-con">
          <div class="le">
            <img
              v-if="idx == 0"
              :src="require('@/assets/images/one.png')"
              alt=""
            />
            <img
              v-else-if="idx == 1"
              :src="require('@/assets/images/two.png')"
              alt=""
            />
            <img
              v-else-if="idx == 2"
              :src="require('@/assets/images/three.png')"
              alt=""
            />
            <span class="serial" v-else>{{ idx + 1 }}</span>
            <span>{{ item.name }}</span>
          </div>
          <span v-if="type == 1" class="number">{{ item.percent }}张</span>
          <span v-else class="number">{{ item.percent }}次</span>
        </div>
        <a-progress
          :stroke-color="{
            from: '#23FFE2',
            to: '#0F86EB',
          }"
          :percent="item.number"
          status="active"
        />
      </div>
    </div>
    <div v-else class="available">
      <a-empty />
    </div>
  </div>
</template>

<script>
import { Tooltip, Progress } from "ant-design-vue";
export default {
  components: {
    "a-progress": Progress,
  },
  props: {
    hgzTopTen: Array,
    type: Number,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.prints-list {
  margin-top: 10px;
  margin-bottom: 10px;
  .title-con {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    .le {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 0 5px;
      img {
        width: 31px;
        height: 26px;
        margin-right: 5px;
      }
      .serial {
        width: 9px;
        font-size: 18px;
        font-family: DIN-Bold;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        margin-right: 23px;
      }
      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
    .number {
      font-size: 20px;
      font-family: DIN-Bold;
      font-weight: 400;
      color: #0bc8fd;
      line-height: 24px;
    }
  }
  ::v-deep .ant-progress-bg {
    height: 12px !important;
  }
}
::v-deep .ant-progress-text {
  opacity: 0 !important;
}
.available {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
</style>
