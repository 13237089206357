import BYMixins from "@/mixins/index/index.js";
import { Progress, Tooltip, message, badge } from "ant-design-vue";
import "ant-design-vue/lib/tooltip/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import "ant-design-vue/lib/progress/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import module from "@/components/module/index.vue";
import * as echarts from "echarts";
import "echarts-gl"; //echarts 3D插件

import overlayApi from "@/request/api/overlay.js";
import loginApi from "@/request/api/user.js";
import { mapGetters } from "vuex";
import moment from "moment";
import subjectData from "@/components/subject-data/index";
import qyscore from "@/components/qyscore/index";
import quickData from "@/components/quick-dete/index";
import dailyData from "@/components/daily-ins/index";
import wghData from "@/components/wgh-data/index";
import printData from "@/components/print-data/index";
import lpybData from "@/components/lpyb-data/index";
import specialJc from "@/components/special-jc/index";
import axios from "axios";
import dict from "@/mixins/index/index.js";

import countTo from "vue-count-to";
// import { heatmapData } from "./heatdata";
export default {
  name: `Overlay`,
  data() {
    return {
      //动画相关开始
      scrlshowin: true,
      scrlshowins: true,
      scrlshowinr: true,
      scrlshowout: false,
      scrlshowous: false,
      scrlshowour: false,
      scrlshowinm: true,
      scrlshowoutm: false,
      //动画相关结束
      //右边区域动画
      scrlshowinri: true,
      scrlshowoutri: false,
      scrlshowinrit: true,
      scrlshowoutrit: false,
      scrlriSshowin: true,
      scrlriSshowout: false,
      scrlrisoshowin: true,
      scrlrisoshowout: false,
      // 需要滚动的时间
      duration: 5000,
      // 初始值
      startVal: 0,
      leftOne: {
        totalSubjectNum: 0,
        certSubjectNum: 0,
        certificateNum: 0,
        batchNum: 0,
      },
      leftTwototal: 0, //检测样品总数
      gridMan: "",
      mapList: [],
      rightTwo: {
        total: 0,
        totalSupervisor: 0,
        totalAssistant: 0,
      },
      centreBottom: [],
      patrolList: [],
      messageList: [], //监管消息
      recSwiperOption: {
        initialSlide: 0,
        slidesPerView: 8,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiper: true,
      recSwiperOptionjg: {
        initialSlide: 0,
        slidesPerView: 3,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperjg: true,
      bg: "",
      yearMonth: moment().format("YYYY-MM"), //时间
      isyearMonth: true,
      isShowMap: false, //记录是否点击地图
      regionCode: "",
      regionLevel: "",
      regionName: "",
      region: "",
      qiyePopShow: false,
      loading: false,
      jiancPopShow: false,
      ricPopShow: false,
      anqjgPopShow: false,
      printPopShow: false,
      scorePopShow: false,
      jiangdouPopShow: false,
      productName: "",
      key: new Date().getTime(),
      lpPopShow: false,
      productNameList: [],
      mapShow: false,
      isSet: true,
      charPie3currentIndex: 0,
      tut: require(`@/assets/images/tut.png`),
      xjxc: require(`@/assets/images/xjxc.png`),
      jyjc: require(`@/assets/images/jyjc.png`),
      hgzkj: require(`@/assets/images/hgzkj.png`),
      sjzlData: {
        certificateCount: 0,
        patrolCount: 0,
        detectionCount: 0,
      }, //数据总览
      hgzmrdysl: [], //合格证每日打印数量
      endTime: "",
      beganTime: "",
      //当前月
      month: moment().format("YYYY-MM"),
      mapCon: {},
      timersetInterval: 0,
      progressBCount: 21,
      progressCount: 10,
      progresstiems: 0,
      progressDirect: "forward", //forward 前进 back 后退
      heatmapData: [],
      hgzShowInfo: {
        name: "",
        jiandu: 0,
        jiance: 0,
        kaiju: 0,
      },
      greenCodeList: [],
      yellowCodeList: [],
      redCodeList: [],
      //记录是否点击进入二级地图
      mapSecondaryShow: 1,
      jgcjSl: 0, //机构抽检
      lscjSl: 0, //临时抽检
      jtjjcSl: 0, //胶体金
      percentCheckSum: 0, //机构抽检
      percentTemporSum: 0, //临时抽检
      percentCardSum: 0, //胶体金
      printShow: false,
      patrolShow: false,
      detectionShow: false,
      time: 3,
    };
  },

  components: {
    "a-tooltip": Tooltip,
    "a-progress": Progress,
    "a-badge": badge,
    module,
    subjectData,
    quickData,
    dailyData,
    wghData,
    printData,
    lpybData,
    countTo,
    qyscore,
    specialJc,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mixins: [dict],
  async mounted() {
    console.log(this.month);
    // https://tinypng.com/  压缩图片工具
    await this.dictionary();
    this.getData();
    this.getMapData();
    this.getJGMessage();
    this.endTime = moment().format("YYYY-MM-DD");
    this.beganTime = moment(this.endTime)
      .subtract(30, "days")
      .format("YYYY-MM-DD");
    this.getHgzmrdys();
    this.getspecialDetection();
    this.getAnimation();
  },
  methods: {
    getAnimation() {
      setTimeout(() => {
        this.scrlshowin = false;
        this.scrlshowout = true;
      }, 100);
      setTimeout(() => {
        this.scrlshowins = false;
        this.scrlshowous = true;
      }, 200);
      setTimeout(() => {
        this.scrlshowinr = false;
        this.scrlshowour = true;
      }, 300);
      //右边区域
      setTimeout(() => {
        this.scrlshowinri = false;
        this.scrlshowoutri = true;
      }, 100);
      setTimeout(() => {
        this.scrlshowinrit = false;
        this.scrlshowoutrit = true;
      }, 200);
      setTimeout(() => {
        this.scrlrisoshowin = false;
        this.scrlrisoshowout = true;
      }, 300);
      setTimeout(() => {
        this.scrlriSshowin = false;
        this.scrlriSshowout = true;
      }, 400);
    },
    loadGridWalk() {
      if (this.progressCount == this.progressBCount) {
        // this.progressDirect = "back";
        this.progressCount = 0;
      }
      // if (this.progressCount == 1) {
      //   this.progressDirect = "forward";
      // }
      if (this.progressDirect == "forward") {
        this.progressCount += 1;
      } else {
        this.progressCount -= 1;
      }
      if (this.gridWalkTimer) {
        clearTimeout(this.gridWalkTimer);
      }
      this.gridWalkTimer = setTimeout(() => {
        this.loadGridWalk();
      }, 1000);
    },
    loadHeatMap() {
      let latitude = "";
      let longitude = "";
      if (this.regionCode == "500113000000") {
        //巴南
        latitude = 106.540603;
        longitude = 29.402348;
      } else if (this.regionCode == "500119000000") {
        //南川
        latitude = 107.099147;
        longitude = 29.157879;
      } else if (this.regionCode == "500116000000") {
        //江津
        latitude = 106.264435;
        longitude = 29.319984;
      } else if (this.regionCode == "500233000000") {
        //忠县
        latitude = 108.038073;
        longitude = 30.299817;
      } else if (this.regionCode == "500235000000") {
        //云阳
        latitude = 108.6975;
        longitude = 30.930628;
      } else if (this.regionCode == "500230000000") {
        //丰都
        latitude = 107.731056;
        longitude = 29.863785;
      } else if (this.regionCode == "500242000000") {
        //"酉阳土家族苗族自治县"
        latitude = 108.76726;
        longitude = 28.841409;
      } else if (this.regionCode == "500151000000") {
        //铜梁
        latitude = 106.056265;
        longitude = 29.845248;
      } else if (this.regionCode == "500114000000") {
        //黔江
        latitude = 108.770677;
        longitude = 29.533609;
      } else if (this.regionCode == "500152000000") {
        //潼南
        latitude = 105.840487;
        longitude = 30.191077;
      } else if (this.regionCode == "500229000000") {
        //城口
        latitude = 108.664349;
        longitude = 31.947319;
      } else if (this.regionCode == "500156000000") {
        //武隆
        latitude = 107.759955;
        longitude = 29.325707;
      } else {
        //重庆
        latitude = 106.540603;
        longitude = 29.402348;
      }
      let zoom = 0;
      if (this.regionCode == "500000000000") {
        zoom = 6;
      } else {
        zoom = 8;
      }
      let _this = this;
      var map = new AMap.Map("heatMap", {
        resizeEnable: true,
        center: [latitude, longitude],
        zoom,
        mapStyle: "amap://styles/darkblue",
      });
      var heatmap;
      map.plugin(["AMap.HeatMap"], function () {
        //初始化heatmap对象
        heatmap = new AMap.HeatMap(map, {
          radius: 15, //给定半径
          // opacity: [0, 0.8],
        });
        heatmap.setDataSet({
          data: _this.heatmapData,
          max: 100,
        });
      });
    },

    async dictionary() {
      await Promise.all([this.getInfo()]);
    },
    paramsdata() {
      let { meshUser } = this.userInfo;
      if (meshUser) {
        this.regionName = meshUser.regionName;
        this.region = meshUser.region;
        // this.regionCode = meshUser.region;
        this.regionLevel = meshUser.regionLevel;
        if (meshUser.cityCode) {
          this.regionCode = meshUser.cityCode;
        }
        if (meshUser.districtCode) {
          this.regionCode = meshUser.districtCode;
        }
        if (meshUser.townCode) {
          this.regionCode = meshUser.townCode;
        }
      }
    },
    async getInfo() {
      try {
        let info = await loginApi.info();
        this.$store.commit("setUserInfo", info);
        if (info.meshUser) {
          let regionName = info.meshUser.regionName;
          if (
            regionName.indexOf("酉阳") != -1 ||
            regionName.indexOf("石柱") != -1 ||
            regionName.indexOf("秀山") != -1 ||
            regionName.indexOf("彭水") != -1
          ) {
            let name1 = regionName.substring(0, 2);
            let name2 = regionName.substr(-1);
            let region = `${name1}${name2}`;
            this.$store.commit("setRegion", region);
          } else {
            this.$store.commit("setRegion", regionName);
          }
        }
        this.paramsdata();
      } catch (error) {
        console.log(error.msg);
      }
    },
    async getData() {
      (async () => {
        //日差巡查
        try {
          let params = {
            region: this.regionCode,
            regionLevel: this.regionLevel,
            pageSize: 100,
            pageNum: 1,
          };
          let res = await overlayApi.patrolRecord(params);
          this.patrolList = res.data.list;
        } catch (error) {
          console.log(error);
        }
      })();
      (async () => {
        try {
          let params = {
            region: this.regionCode,
            regionLevel: this.regionLevel,
          };

          let res = await overlayApi.getBigScreen(params);
          let { leftOne, leftTwo, rightOne, rightTwo } = res.data;
          if (this.regionCode == "500242000000") {
            leftOne.certificateNum = leftOne.certificateNum + 207;
          }
          this.leftOne = leftOne;
          this.fastChart = leftTwo.labelList.map((item) => {
            return {
              value: item.number,
              name: item.label,
            };
          });

          this.leftTwototal = leftTwo.total;
          this.productNameList = leftTwo.productNameList;
          this.gridMan = rightOne;
          this.rightTwo = rightTwo;
          await this.$nextTick();
          this.loadFastChart();
        } catch (error) {
          console.log(error);
        }
      })();
      (async () => {
        //数据总览
        try {
          let params = {
            region: this.regionCode,
            regionLevel: this.regionLevel,
          };
          let response = await overlayApi.tionCount(params);
          let sjzlData = response.data;
          if (
            this.regionCode == "500000000000" ||
            this.regionCode == "500242000000"
          ) {
            sjzlData.certificateCount = sjzlData.certificateCount + 7139;
          }
          this.sjzlData = sjzlData;
        } catch (error) {
          console.log(error);
        }
      })();
      (async () => {
        //获取合格证打印数量
        try {
          let params = {
            region: this.regionCode,
            regionLevel: this.regionLevel,
          };
          let res = await overlayApi.hgzdysl(params);
          for (let index = 0; index < res.data.length; index++) {
            const item = res.data[index];
            if (item.regionName.length > 4) {
              item.regionName = item.regionName.substring(0, 2);
            }
            if (item.regionName == "桃花源街道") {
              item.printCount = parseInt(item.printCount) + 59;
            }
            if (item.regionName == "钟多街道") {
              item.printCount = parseInt(item.printCount) + 527;
            }
            if (item.regionName == "龙潭镇") {
              item.printCount = parseInt(item.printCount) + 816;
            }
            if (item.regionName == "麻旺镇") {
              item.printCount = parseInt(item.printCount) + 5452;
            }
            if (item.regionName == "兴隆镇") {
              item.printCount = parseInt(item.printCount) + 11;
            }
            if (item.regionName == "铜鼓镇") {
              item.printCount = parseInt(item.printCount) + 60;
            }
            if (item.regionName == "五福镇") {
              item.printCount = parseInt(item.printCount) + 194;
            }
            if (item.regionName == "偏柏乡") {
              item.printCount = parseInt(item.printCount) + 19;
            }
            if (item.regionName == "庙溪乡") {
              item.printCount = parseInt(item.printCount) + 1;
            }
            if (item.region == "500242000000") {
              item.printCount = item.printCount + 7139;
            }
          }
          let list = res.data;
          list.sort(function (a, b) {
            return b.printCount - a.printCount;
          });
          let result = list.slice(0, 8);
          this.centreBottom = result;
          await this.$nextTick();
          this.barChart();
        } catch (error) {
          console.log(error);
        }
      })();
      (async () => {
        //企业评分
        try {
          let query = {
            region: this.regionCode,
            regionLevel: this.regionLevel,
          };
          let response = await overlayApi.subjectScore(query);
          let list = response.data;
          this.greenCodeList = [];
          this.yellowCodeList = [];
          this.redCodeList = [];
          list.forEach((element) => {
            if (element.level == 1) {
              this.greenCodeList = element.subjectNameAndLevel;
            } else if (element.level == 2) {
              this.yellowCodeList = element.subjectNameAndLevel;
            } else {
              this.redCodeList = element.subjectNameAndLevel;
            }
          });
        } catch (error) {
          console.log(error);
        }
      })();
    },
    //合格证每日打印数
    async getHgzmrdys() {
      //合格证每日打印数量
      try {
        let params = {
          region: this.regionCode,
          regionLevel: this.regionLevel,
          beganTime: `${this.beganTime} 00:00:00`,
          endTime: `${this.endTime} 23:59:59`,
        };
        let response = await overlayApi.hgzmrdysl(params);
        this.hgzmrdysl = response.data;
        await this.$nextTick();
        this.lineChart();
      } catch (error) {
        console.log(error);
      }
    },
    //获取豇豆专项检测
    async getspecialDetection() {
      let params = {
        regionCode: this.regionCode,
      };
      if (this.regionCode == "500000000000") {
        params.regionCode = "";
      }
      try {
        let res = await overlayApi.specialCount(params);
        let { checkCardSum, checkSum, checkTemporarySum } = res.data;
        let total = checkSum + checkTemporarySum + checkCardSum;
        this.percentCheckSum = parseInt(((checkSum / total) * 100).toFixed(2));
        this.percentTemporSum = parseInt(
          ((checkTemporarySum / total) * 100).toFixed(2)
        );
        this.percentCardSum = parseInt(
          ((checkCardSum / total) * 100).toFixed(2)
        );
        this.jgcjSl = checkSum;
        this.lscjSl = checkTemporarySum;
        this.jtjjcSl = checkCardSum;
      } catch (error) {
        console.log(error);
      }
    },
    async getMapData() {
      try {
        let params = {
          region: this.regionCode,
          regionLevel: this.regionLevel,
        };
        let res = await overlayApi.homemap(params);
        let list = res.data;
        for (let index = 0; index < list.length; index++) {
          let element = list[index];
          if (element.region == "500242000000") {
            element.printCount = parseInt(element.printCount) + 7139;
          }
          if (element.regionName == "桃花源街道") {
            element.printCount = parseInt(element.printCount) + 59;
          }
          if (element.regionName == "钟多街道") {
            element.printCount = parseInt(element.printCount) + 527;
          }
          if (element.regionName == "龙潭镇") {
            element.printCount = parseInt(element.printCount) + 816;
          }
          if (element.regionName == "麻旺镇") {
            element.printCount = parseInt(element.printCount) + 5452;
          }
          if (element.regionName == "兴隆镇") {
            element.printCount = parseInt(element.printCount) + 11;
          }
          if (element.regionName == "铜鼓镇") {
            element.printCount = parseInt(element.printCount) + 60;
          }
          if (element.regionName == "五福镇") {
            element.printCount = parseInt(element.printCount) + 194;
          }
          if (element.regionName == "偏柏乡") {
            element.printCount = parseInt(element.printCount) + 19;
          }
          if (element.regionName == "庙溪乡") {
            element.printCount = parseInt(element.printCount) + 1;
          }
        }
        let mapList = list.map((item) => {
          return {
            name: item.regionName,
            value: `${item.printCount}, ${item.patrolNumber}, ${item.checkNumber}`,
            region: item.region,
            regionLevel: item.regionLevel,
          };
        });
        this.mapList = mapList.filter((item) => {
          return item;
        });
        this.heatmapData = list.map((item) => {
          return {
            lng: item.lat,
            lat: item.lon,
            count: item.printCount,
          };
        });
        await this.$nextTick();
        this.loadGridWalk();
        this.loadHeatMap();
        let { meshUser } = this.userInfo;
        if (this.isShowMap) {
          if (this.regionCode) {
            this.mapJson(this.regionCode);
          }
        } else {
          if (meshUser) {
            if (!meshUser.districtCode) {
              //重庆市
              try {
                let axinstance = axios.create({
                  baseURL: "/",
                });
                let response = await axinstance.get("chongqing.json");
                console.log(response.data);
                let jsonCqData = response.data;
                // this.loadMap(jsonCqData);
                this.getMapData2d(jsonCqData);
              } catch (error) {
                console.log("error");
              }
            } else {
              this.mapJson(meshUser.districtCode);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    //获取监管消息
    async getJGMessage() {
      let params = {
        pageNum: 1,
        pageSize: 100,
      };
      if (this.regionLevel == 2) {
        params.cityCode = this.regionCode;
      } else if (this.regionLevel == 3) {
        params.districtCode = this.regionCode;
      } else if (this.regionLevel == 4) {
        params.townCode = this.regionCode;
      } else if (this.regionLevel == 5) {
        params.streetCode = this.regionCode;
      }
      try {
        let response = await overlayApi.jgMessage(params);
        this.messageList = response.data.list;
        setTimeout(() => {
          this.scrlshowinm = false;
          this.scrlshowoutm = true;
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
    async mapJson(code) {
      if (code == "500230000000") {
        //丰都县
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("fengdu.json");
          console.log(response.data);
          let jsonFdData = response.data;
          this.getMapData2d(jsonFdData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500242000000") {
        //酉阳
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("youyang.json");
          console.log(response.data);
          let jsonYYData = response.data;
          this.getMapData2d(jsonYYData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500114000000") {
        //黔江
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("qianjiang.json");
          console.log(response.data);
          let jsonSZData = response.data;
          this.getMapData2d(jsonSZData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500113000000") {
        //巴南
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("bananqu.json");
          console.log(response.data);
          let jsonBNData = response.data;
          this.getMapData2d(jsonBNData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500116000000") {
        //江津
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("jiangjinqu.json");
          console.log(response.data);
          let jsonJJData = response.data;
          this.getMapData2d(jsonJJData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500119000000") {
        //南川
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("nanchuanqu.json");
          console.log(response.data);
          let jsonNCData = response.data;
          this.getMapData2d(jsonNCData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500235000000") {
        //云阳
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("yunyangxian.json");
          console.log(response.data);
          let jsonYYQData = response.data;
          this.getMapData2d(jsonYYQData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500233000000") {
        //忠县
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("zhongxian.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500151000000") {
        //铜梁
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("tongliang.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500156000000") {
        //武隆
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("wulongqu.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500152000000") {
        //潼南
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("tongnan.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      } else if (code == "500229000000") {
        //城口
        try {
          let axinstance = axios.create({
            baseURL: "/",
          });
          let response = await axinstance.get("chengkou.json");
          console.log(response.data);
          let jsonZXData = response.data;
          this.getMapData2d(jsonZXData);
        } catch (error) {
          console.log("error");
        }
      }
    },

    // 农产品质量安全检测
    loadFastChart() {
      let _this = this;
      let index = 0;
      var chartDom = document.getElementById("pie");
      var myChart = echarts.init(chartDom);
      let option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "-3%",
          left: "center",
          itemWidth: 10,
          icon: "circle",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["50%", "80%"],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 3,
            },
            label: {
              show: false,
              position: "center",
            },

            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.fastChart,
          },
        ],
      };

      myChart.setOption(option);
      function highlightNextFaPoint() {
        myChart.dispatchAction({
          type: "hideTip",
          seriesIndex: 0,
          dataIndex: index,
        });
        // 显示提示框
        myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: index,
        });
        // 取消高亮指定的数据图形
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: index == 0 ? 4 : index - 1,
        });
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: index,
        });
        index++;
        if (index > 4) {
          index = 0;
        }
      }
      if (_this.anquankuaisujianc != 0) {
        clearInterval(_this.anquankuaisujianc);
      }
      // 设置定时器来循环高亮点
      _this.anquankuaisujianc = setInterval(highlightNextFaPoint, 3000);
      //鼠标移入
      myChart.on("mouseover", (e) => {
        // console.log("鼠标移入", e);
        clearInterval(_this.anquankuaisujianc);
        // 取消高亮指定的数据图形
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: index == 0 ? 4 : index - 1,
        });
      });
      //鼠标移出
      myChart.on("globalout", (e) => {
        // console.log(e);
        // currentFastIndex = -1;
        clearInterval(this.anquankuaisujianc);
        this.anquankuaisujianc = setInterval(highlightNextFaPoint, 3000);
      });
    },
    // 合格证打印数量
    barChart() {
      var chartDom = document.getElementById("bar");
      try {
        this.barChartInstance.clear();
      } catch (error) {
        console.log(error);
      }
      this.barChartInstance = echarts.init(chartDom);
      let myChart = this.barChartInstance;
      let salvProName = this.centreBottom.map((item) => {
        return item.regionName;
      });
      let salvProValue = this.centreBottom.map((item) => {
        return item.printCount;
      });
      let option = {
        // backgroundColor: "#003366",
        grid: {
          left: "0%",
          right: "11%",
          bottom: "2%",
          top: "2%",
          containLabel: true,
        },

        tooltip: {
          trigger: "axis",
          fontSize: 14,
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            return params[0].name + " : " + params[0].value + "张";
          },
        },

        xAxis: {
          // show: false,
          type: "value",
          max: "dataMax",
          data: salvProName,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            // animationDuration: 300,
            // animationDurationUpdate: 300,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: 14,
              },
            },
            // splitLine: {
            //   show: false,
            // },
            // axisTick: {
            //   show: false,
            // },
            // axisLine: {
            //   show: false,
            // },
            data: salvProName,
          },
        ],
        dataZoom: [
          {
            type: "inside",
            // minValueSpan: 5,
            maxValueSpan: 5,
            startValue: 0, // 从头开始。
            endValue: 10, // 一次性展示多少个。
            yAxisIndex: [0], //控制的y轴 —x 轴 的话 换一下就可以
          },
        ],
        series: [
          {
            name: "值",
            type: "bar",
            // realtimeSort: true,
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "rgb(57,89,255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgb(46,200,207,1)",
                  },
                ]),
                borderColor: "#D2F8FF",
                fontSize: 12,
              },
            },

            label: {
              show: true,
              position: "outside",
            },
            barWidth: 15,
            data: salvProValue,
            // sorting: "descending",
          },
        ],
      };
      myChart.setOption(option);
      // 自动滚动dataZoom的代码
      var currentStart = 0; // 初始的dataZoom起始位置
      var currentEnd = 5; // 初始的dataZoom结束位置

      function autoScrollDataZoom() {
        // 更新dataZoom的起始和结束位置
        currentStart += 1;
        currentEnd += 1;
        // console.log("salvProValue is ", salvProValue);
        // 控制dataZoom的最大值，以防止超出数据范围
        if (currentEnd > salvProValue.length) {
          currentStart = 0;
          currentEnd = 5;
        }
        // 更新dataZoom
        myChart.setOption({
          dataZoom: [
            {
              startValue: currentStart,
              endValue: currentEnd,
            },
          ],
        });
      }

      // 设置定时器来自动滚动dataZoom
      if (this.hgzScrollTimer) {
        clearInterval(this.hgzScrollTimer);
      }
      this.hgzScrollTimer = setInterval(autoScrollDataZoom, 4000); // 1000毫秒（1秒）滚动一次
    },
    //合格证每日打印数量
    lineChart() {
      let _this = this;
      let show = false;
      var chartDom = document.getElementById("line");
      var myChart = echarts.init(chartDom);
      let list = this.hgzmrdysl.slice(0, 3);
      let tradeType = list.map((item) => {
        return item.tradeType;
      });

      let series = [];
      let createDates = [];
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        let ps = element.printDayNumVos.map((item) => {
          return item.printCount;
        });
        let tempCreateDate = element.printDayNumVos.map((item) => {
          return item.createDate.substring(item.createDate.length - 5);
        });
        createDates = tempCreateDate;
        let item = {
          name: element.tradeType,
          data: ps,
          type: "line",
          itemStyle: {
            normal: { color: "#19AEFB" },
          },
          label: {
            // 设置label属性
            show: false, // 显示文字
            color: "#19AEFB", // 设置文字颜色为红色
          },
        };
        let color = "#14EF9A";
        if (index == 0) {
          color = "#14EF9A";
        } else if (index == 1) {
          color = "#19AEFB";
        } else {
          color = "#EF9F14";
        }
        item.itemStyle.normal.color = color;
        item.label.color = color;

        series.push(item);
      }
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: {
            type: "linear", // 设置渐变类型
            x: 0, // 渐变的起始点
            y: 0, // 渐变的结束点
            x2: 0, // 渐变的起始点
            y2: 1, // 渐变的结束点
            colorStops: [
              { offset: 0, color: "rgba(9,81,226,0.1)" }, // 渐变的起始颜色
              { offset: 1, color: "rgba(9,81,226,0.3)" }, // 渐变的结束颜色
            ],
            globalCoord: false, // 渐变的坐标是否是全局坐标
          },
          borderColor: "#13CAFF",
          textStyle: {
            // 设置文字样式
            color: "#ffffff", // 设置文字颜色为红色
          },
        },
        legend: {
          data: tradeType,
          top: "10",
          right: "0",
          textStyle: {
            color: "#96B6D0",
          },
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
              },
            },
          },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        xAxis: [
          {
            type: "category",
            data: createDates,

            splitLine: {
              show: false,
            },

            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
        ],
        yAxis: {
          type: "value",

          splitLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        series: series,
      };
      myChart.setOption(option);
      // 自动循环高亮点的代码
      var currentIndex = -1;
      function highlightNextPoint() {
        // console.log("highlightNextPoint called");
        var dataLen = createDates.length;

        // 取消上一个点的高亮
        // myChart.dispatchAction({
        //   type: "downplay",
        //   seriesIndex: 0,
        //   dataIndex: currentIndex,
        // });

        currentIndex = (currentIndex + 1) % dataLen;

        // 高亮当前点
        // 显示 tooltip
        myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: currentIndex,
        });
      }
      if (_this.timersetInterval != 0) {
        clearInterval(_this.timersetInterval);
      }
      // 设置定时器来循环高亮点
      _this.timersetInterval = setInterval(highlightNextPoint, 2000); // 1000毫秒（1秒）循环一次
      //鼠标移入
      myChart.on("mouseover", (e) => {
        // console.log("鼠标移入", e);
        clearInterval(_this.timersetInterval);
      });
      //鼠标移出
      myChart.on("globalout", (e) => {
        // console.log(e);
        // currentIndex = -1;
        clearInterval(this.timersetInterval);
        this.timersetInterval = setInterval(highlightNextPoint, 2000);
      });
    },
    async getMapData2d(jsonCqData, mapName = "chongqing") {
      let _this = this;
      if (!this.myChartmap) {
        this.myChartmap = echarts.init(document.getElementById("dtchart"));
      } else {
        // 点击下转市级地图发现边框还是上个地图的区域样式，与新的地图区域不对照，此方法就是清除上个地图的边框样式
        this.myChartmap.clear();
      }
      let data = jsonCqData.features.map((item) => {
        return {
          name: item.properties.name,
        };
      });

      this.mapShow = true;
      await this.$nextTick();

      let resultValueList = this.mapList.map((item) => {
        return {
          name: item.name,
          value: 10,
          valueStrs: item.value,
          region: item.region,
          regionLevel: item.regionLevel,
        };
      });
      echarts.registerMap(mapName, jsonCqData);
      let option = {
        tooltip: {
          show: true,
          // 这里可以自定义需要显示的文本内容
          formatter: function (obj) {
            console.log("obj is", obj);
            console.log("鼠标移入");
            if (obj.data) {
              let { name, valueStrs } = obj.data;
              // clearInterval(_this.dituScrollTimer);
              let data = valueStrs.split(",");
              _this.hgzShowInfo = {
                name,
                jiandu: data[1],
                jiance: data[2],
                kaiju: data[0],
              };
            }

            // let { name, data: _data } = obj;

            // return `
            // <div style='border:1px solid #1149E8;heigth:215px'>
            //  <div style="position: relative">
            //     <img style="width:260px;height:39px" src="${_this.tut}" />
            //     <span style="position: absolute; top: 40%;left:50%;transform: translate(-50%,-60%);color:#fff">${name}</span>
            //  </div>
            //   <div style="display: flex; flex-direction:row;padding:10px ">
            //     <img style="width:25px;height:25px" src="${_this.xjxc}" />
            //     <div style="width:100%">
            //       <div style="width:100%;display: flex; flex-direction:row;align-items:center;justify-content: space-between;">
            //           <span style="color:#fff">监督巡查</span>
            //           <span style="color:#fff">${data[1]}次</span>
            //       </div>
            //       <div style="width: 113px;
            //       height: 12px;
            //       background: #19B1FF;
            //       border-radius: 2px;">
            //       </div>
            //     </div>
            //   </div>
            //   <div style="display: flex; flex-direction:row;padding:10px ">
            //      <img style="width:25px;height:25px" src="${_this.jyjc}" />
            //      <div style="width:100%">
            //        <div style="width:100%;display: flex; flex-direction:row;align-items:center;justify-content: space-between;">
            //            <span style="color:#fff">检验检测</span>
            //            <span style="color:#fff">${data[2]}次</span>
            //        </div>
            //        <div style="width: 113px;
            //        height: 12px;
            //        background: #EF9F14;
            //        border-radius: 2px;">
            //      </div>
            //      </div>
            //   </div>
            //   <div style="display: flex; flex-direction:row;padding:10px ">
            //      <img style="width:25px;height:25px" src="${_this.hgzkj}" />
            //      <div style="width:100%">
            //         <div style="width:100%;display: flex; flex-direction:row;align-items:center;justify-content: space-between;">
            //             <span style="color:#fff">合格证开具</span>
            //             <span style="color:#fff">${data[0]}张</span>
            //         </div>
            //         <div style="width: 113px;
            //         height: 12px;
            //         background: #14EF9A;
            //         border-radius: 2px;">
            //         </div>
            //      </div>
            //   </div>
            // </div>
            // `;
          },
        },
        geo: [
          {
            map: mapName,
            aspectScale: 1,
            zoom: 0.55,
            layoutCenter: ["50%", "50%"],
            layoutSize: "180%",
            show: true,
            roam: false,
            label: {
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                borderColor: "rgba(192,245,249,1)",
                borderWidth: 3,
                shadowColor: "#6FFDFF",
                shadowOffsetY: 0,
                shadowBlur: 10,
                areaColor: "rgba(29,85,139,.6)",
              },
            },
            emphasis: {
              areaColor: "rgba(29,85,139,.6)",
            },
          },
          // {
          //   map: mapName,
          //   aspectScale: 1,
          //   zoom: 0.55,
          //   layoutCenter: ["50%", "50%"],
          //   layoutSize: "180%",
          //   show: true,
          //   roam: false,
          //   label: {
          //     emphasis: {
          //       show: false,
          //     },
          //   },
          //   itemStyle: {
          //     normal: {
          //       borderColor: "3c0f3fb",
          //       borderWidth: 3,
          //       shadowColor: "#8cd3ef",
          //       shadowOffsetY: 10,
          //       shadowBlur: 120,
          //       areaColor: "rgba(29,85,139,.6)",
          //     },
          //   },
          //   emphasis: {
          //     areaColor: "rgba(29,85,139,.6)",
          //   },
          // },
          // 重影
          {
            type: "map",
            map: mapName,
            zlevel: -1,
            aspectScale: 1,
            zoom: 0.55,
            layoutCenter: ["50%", "50.7%"],
            layoutSize: "180%",
            roam: false,
            silent: true,
            itemStyle: {
              normal: {
                borderWidth: 4,
                borderColor: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#20a2e1", // 0% 处的颜色
                    },
                    {
                      offset: 0.8,
                      color: "#085473", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#054660", // 50% 处的颜色
                    },
                  ],
                },
                shadowColor: "rgba(172, 122, 255,0.5)",
                shadowOffsetY: 5,
                shadowBlur: 15,
                areaColor: "rgba(5,21,35,0.1)",
              },
            },
          },
          {
            type: "map",
            map: mapName,
            zlevel: -2,
            aspectScale: 1,
            zoom: 0.55,
            layoutCenter: ["50%", "51.4%"],
            layoutSize: "180%",
            roam: false,
            silent: true,
            itemStyle: {
              normal: {
                borderWidth: 5,
                borderColor: "rgba(65, 214, 255,1)",
                shadowColor: "rgba(65, 214, 255,0.6)",
                shadowOffsetY: 5,
                shadowBlur: 15,
                areaColor: "rgba(5,21,35,0.1)",
              },
            },
          },
          {
            type: "map",
            map: mapName,
            zlevel: -3,
            aspectScale: 1,
            zoom: 0.55,
            layoutCenter: ["50%", "52.1%"],
            layoutSize: "180%",
            roam: false,
            silent: true,
            itemStyle: {
              normal: {
                borderWidth: 6,
                borderColor: "rgba(29, 111, 165,1)",
                shadowColor: "rgba(29, 111, 165,0.5)",
                shadowOffsetY: 15,
                shadowBlur: 8,
                areaColor: "rgba(5,21,35,0.1)",
              },
            },
          },
        ],
        series: [
          {
            name: "重庆市数据",
            type: "map",
            map: mapName, // 自定义扩展图表类型
            aspectScale: 1,
            zoom: 0.55, // 缩放
            showLegendSymbol: true,
            label: {
              normal: {
                show: true,
                textStyle: { color: "#fff", fontSize: "150%" },
              },
              emphasis: {
                show: true,
                textStyle: { color: "#000", fontSize: "150%" },
              },
            },
            itemStyle: {
              normal: {
                areaColor: {
                  // type: "linear",
                  // x: 1200,
                  // y: 0,
                  // x2: 0,
                  // y2: 0,
                  // colorStops: [
                  //   {
                  //     offset: 0,
                  //     color: "#17498d", // 0% 处的颜色
                  //   },
                  //   {
                  //     offset: 1,
                  //     color: "#3a95fd", // 50% 处的颜色
                  //   },
                  // ],
                  // global: true, // 缺省为 false
                  image: document.getElementById("mapbg"),
                  repeat: "repeat",
                },
                borderColor: "#fff",
                borderWidth: 0.2,
              },
              emphasis: {
                show: false,
                color: "#fff",
                areaColor: "#00fee9",
              },
            },
            layoutCenter: ["50%", "50%"],
            layoutSize: "180%",
            markPoint: {
              symbol: "none",
            },
            data: resultValueList,
            selectedMode: false,
          },
          {
            tooltip: {
              show: false,
            },
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              brushType: "stroke",
            },
            showEffectOn: "render",
            itemStyle: {
              normal: {
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(0,255,246,0.1)",
                    },
                    {
                      offset: 0.6,
                      color: "rgba(0,255,246,0.4)",
                    },
                    {
                      offset: 0.85,
                      color: "transparent",
                    },
                    {
                      offset: 1,
                      color: "#FFF",
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            label: {
              normal: {
                color: "#fff",
              },
            },
            symbol: "circle",
            symbolSize: 18,
            data: resultValueList,
            zlevel: 1,
            selectedMode: false,
          },
        ],
      };
      let myChartmap = this.myChartmap;
      myChartmap.setOption(option, true);
      myChartmap.off("click");
      myChartmap.on("click", mapClickHandler); //第一个参数为事件类型，第二个参数为回调函数
      function mapClickHandler(params) {
        clearInterval(_this.dituScrollTimer); //清空定时器
        // console.log("定时器", _this.dituScrollTimer);
        if (params.componentType === "series" && params.seriesType === "map") {
          //只处理地图的点击事件
          let { name, data } = params;
          // console.log("params", params);
          if (!data) {
            if (_this.mapSecondaryShow == 2) {
              //说明是点击了上级地图
              _this.backHand();
            }
            if (_this.userInfo.meshUser.regionLevel < 2) {
              //说明是市级以下 没有数据的时候就需要返回上一级
              _this.backHand();
            }
          } else {
            let { region, regionLevel } = data;
            if (
              region == "500230000000" ||
              region == "500242000000" ||
              region == "500113000000" ||
              region == "500116000000" ||
              region == "500119000000" ||
              region == "500235000000" ||
              region == "500233000000" ||
              region == "500151000000" ||
              region == "500114000000" ||
              region == "500152000000" ||
              region == "500229000000" ||
              region == "500156000000"
            ) {
              if (regionLevel > 3) {
                return;
              }
              let regionName = name;
              if (
                regionName.indexOf("酉阳") != -1 ||
                regionName.indexOf("石柱") != -1 ||
                regionName.indexOf("秀山") != -1 ||
                regionName.indexOf("彭水") != -1
              ) {
                let name1 = regionName.substring(0, 2);
                let name2 = regionName.substr(-1);
                let region = `${name1}${name2}`;
                _this.$store.commit("setRegion", region);
              } else {
                _this.$store.commit("setRegion", name);
              }
              _this.isShowMap = true;
              _this.regionCode = region;
              _this.regionName = name;
              _this.regionLevel = 3;
              _this.mapSecondaryShow = 2;
              _this.getData();
              _this.getMapData();
              _this.getHgzmrdys(); //合格证每日数
              _this.getspecialDetection(); //豇豆专项
              _this.getJGMessage(); //消息预警
            } else {
              if (_this.mapSecondaryShow == 2) {
                //说明是点击了上级地图
                _this.backHand();
              }
              // _this.backHand();
            }
          }
        }
      }
      // 自动滚动dataZoom的代码
      let currentStart = 0; // 初始
      function autoScrollDataZoom() {
        let mapList = _this.mapList;

        // let reinx = function (pindex) {
        //   let { name, value } = mapList[pindex];
        //   let vs = value.split(",");
        //   if (vs[0] != 0 || vs[1] != 0 || vs[2] != 0) {
        //     console.log("vs is ", vs[0], vs[1], vs[2]);
        //     currentStart += 1;
        //     reinx(currentStart);
        //   }
        //   return currentStart;
        // };

        // currentStart = reinx(currentStart);
        if (mapList.length != 0) {
          if (currentStart > mapList.length - 1) {
            myChartmap.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: currentStart - 1,
            });
            currentStart = 0;
          }
          let { name, value } = mapList[currentStart];
          let vs = value.split(",");
          _this.hgzShowInfo = {
            name,
            jiandu: vs[1],
            jiance: vs[2],
            kaiju: vs[0],
          };
        }

        if (currentStart > 0) {
          myChartmap.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: currentStart - 1,
          });
        }
        myChartmap.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: currentStart,
        });
        currentStart += 1;
      }

      // 设置定时器来自动滚动dataZoom
      if (this.dituScrollTimer) {
        clearInterval(this.dituScrollTimer);
      }
      this.dituScrollTimer = setInterval(autoScrollDataZoom, 2000); // 1000毫秒（1秒）滚动一次
      myChartmap.on("mouseover", (e) => {
        // console.log("地图鼠标移入", e);
        myChartmap.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: currentStart - 1,
        });
        try {
          clearInterval(_this.dituScrollTimer);
        } catch (error) {
          console.log(error);
        }
      });
      //鼠标移出
      myChartmap.on("globalout", (e) => {
        // console.log(e);
        myChartmap.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: currentStart,
        });
        clearInterval(this.dituScrollTimer);
        this.dituScrollTimer = setInterval(autoScrollDataZoom, 2000);
      });
    },

    //点击地图上方返回
    backHand() {
      this.isShowMap = false;
      this.paramsdata(); //参数
      this.getData();
      this.getMapData();
      this.getHgzmrdys();
      this.getspecialDetection();
      this.getJGMessage();
      let region = this.userInfo.meshUser.regionName;
      this.$store.commit("setRegion", region);
    },
    //点击数据总览
    printChange() {
      this.printShow = !this.printShow;
      setTimeout(() => {
        this.printShow = false;
      }, 3000);
    },
    patrolChange() {
      this.patrolShow = !this.patrolShow;
      setTimeout(() => {
        this.patrolShow = false;
      }, 3000);
    },
    detectionChange() {
      this.detectionShow = !this.detectionShow;
      setTimeout(() => {
        this.detectionShow = false;
      }, 3000);
    },
    //点击主体基本数据
    mainDataHand() {
      this.key = new Date().getTime();
      this.qiyePopShow = true;
    },
    //主体基本数据关闭
    handleqiyeCancel() {
      this.qiyePopShow = false;
    },

    //快速检测
    quickHand() {
      this.key = new Date().getTime();
      this.jiancPopShow = true;
    },
    handlejiancCancel() {
      this.productName = "";
      this.key = new Date().getTime();
      this.jiancPopShow = false;
    },
    richaHand() {
      this.key = new Date().getTime();
      this.ricPopShow = true;
    },
    handlerichaCancel() {
      this.ricPopShow = false;
    },
    //点击右二
    ncqwghHand() {
      this.key = new Date().getTime();
      this.anqjgPopShow = true;
    },
    handleaqjcCancel() {
      this.anqjgPopShow = false;
    },
    //合格证打印数量
    printHand() {
      this.key = new Date().getTime();
      this.printPopShow = true;
    },
    //合格证打印数量
    handleaprintCancel() {
      this.printPopShow = false;
    },
    selectSignHand() {
      this.lpPopShow = true;
    },
    handlealpCancel() {
      this.key = new Date().getTime();
      this.lpPopShow = false;
    },
    //合格证月份
    timeOnChange(date, dateString) {
      this.beganTime = `${dateString}-1`;
      const currentMonth = moment(this.beganTime)
        .endOf("month")
        .format("YYYY-MM-DD");
      this.endTime = currentMonth;
      this.getHgzmrdys();
    },
    //企业评分
    qypfHand() {
      this.scorePopShow = true;
    },
    handleScoreCancel() {
      this.key = new Date().getTime();
      this.scorePopShow = false;
    },
    //豇豆
    jiangdouHand() {
      this.jiangdouPopShow = true;
    },
    handleJiangdouCancel() {
      this.key = new Date().getTime();
      this.jiangdouPopShow = false;
    },
    /**  3d地图
    loadMap(jsonData, mapName = "china") {
      let _this = this;
      if (!this.myChartmap) {
        this.myChartmap = echarts.init(document.getElementById("dtchart"));
      } else {
        // 点击下转市级地图发现边框还是上个地图的区域样式，与新的地图区域不对照，此方法就是清除上个地图的边框样式
        this.myChartmap.clear();
      }
      //这里需要过滤一下mapList的数据需要与地图json数据中的地区名字对应不能存在json数据中没有的地区数据不然会出现高亮不了
      let m = this.mapList.filter((item) => {
        let name = item.name;
        let find = jsonData.features.find((f) => {
          return name == f.properties.name;
        });
        return find;
      });
      this.mapList = m;
      let myChartmap = this.myChartmap;
      let mapdistance = "";

      if (this.regionCode == "500113000000") {
        //巴南
        mapdistance = 200;
      } else if (this.regionCode == "500119000000") {
        //南川
        mapdistance = 230;
      } else if (this.regionCode == "500116000000") {
        //江津
        mapdistance = 200;
      } else if (this.regionCode == "500233000000") {
        //忠县
        mapdistance = 150;
      } else if (this.regionCode == "500235000000") {
        //云阳
        mapdistance = 180;
      } else if (this.regionCode == "500230000000") {
        //丰都
        mapdistance = 190;
      } else if (this.regionCode == "500242000000") {
        //丰都
        mapdistance = 190;
      } else {
        //重庆
        mapdistance = 150;
      }
      echarts.registerMap(mapName, jsonData);
      var viewControl = {
        projection: "perspective",
        // autoRotateDirection: "ccw",
        // autoRotate: true,
        damping: 0.8,
        panSensitivity: 10,
        distance: 120,
        minDistance: 100,
        maxDistance: 120, //最大距离
        center: [0, 5, 0],
        // alpha: 60,
        // beta: -20,
        // animation: true, // 是否开启动画。[ default: true ]
        // animationDurationUpdate: 1000, // 过渡动画的时长。[ default: 1000 ]
        // animationEasingUpdate: "cubicInOut", // 过渡动画的缓动效果。[ default: cubicInOut ]
      };
      let option = {
        tooltip: {
          trigger: "item",
          backgroundColor: "#1868FBA8",
          // borderRadius: 20,
          padding: [0],
          textStyle: {
            // color: "white",
            align: "right",
            fontSize: 14,
          },
          // 这里可以自定义需要显示的文本内容
          formatter: function (obj) {
            let { name, value } = obj;
            let data = value.split(",");
            return `
            <div style='border:1px solid #1149E8;heigth:215px'>
             <div style="position: relative">
                <img style="width:260px;height:39px" src="${_this.tut}" />
                <span style="position: absolute; top: 40%;left:50%;transform: translate(-50%,-60%);color:#fff">${name}</span>
             </div>
              <div style="display: flex; flex-direction:row;padding:10px ">
                <img style="width:25px;height:25px" src="${_this.xjxc}" />
                <div style="width:100%">
                  <div style="width:100%;display: flex; flex-direction:row;align-items:center;justify-content: space-between;">
                      <span style="color:#fff">监督巡查</span>
                      <span style="color:#fff">${data[1]}次</span>
                  </div>
                  <div style="width: 113px;
                  height: 12px;
                  background: #19B1FF;
                  border-radius: 2px;">
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-direction:row;padding:10px ">
                 <img style="width:25px;height:25px" src="${_this.jyjc}" />
                 <div style="width:100%">
                   <div style="width:100%;display: flex; flex-direction:row;align-items:center;justify-content: space-between;">
                       <span style="color:#fff">检验检测</span>
                       <span style="color:#fff">${data[2]}次</span>
                   </div>
                   <div style="width: 113px;
                   height: 12px;
                   background: #EF9F14;
                   border-radius: 2px;">
                 </div>
                 </div>
              </div>
              <div style="display: flex; flex-direction:row;padding:10px ">
                 <img style="width:25px;height:25px" src="${_this.hgzkj}" />
                 <div style="width:100%">
                    <div style="width:100%;display: flex; flex-direction:row;align-items:center;justify-content: space-between;">
                        <span style="color:#fff">合格证开具</span>
                        <span style="color:#fff">${data[0]}张</span>
                    </div>
                    <div style="width: 113px;
                    height: 12px;
                    background: #14EF9A;
                    border-radius: 2px;">
                    </div>
                 </div>
              </div>
            </div>
            `;
          },
        },
        geo3D: {
          name: "地图",
          regionHeight: 5,
          map: mapName,
          label: { show: false },
          // itemStyle: {
          //   color: "rgba(0, 0, 0,0)",
          // },

          shading: `color`,
          emphasis: {
            label: { show: false },
            // itemStyle: {
            //   color: "rgba(0, 0, 0,0)",
            //   // opacity: 0.5,
            // },
          },

          // select: {
          //   itemStyle: {
          //     // color: this.clickcolor, // 设置地图点击后的颜色
          //     color: "#FFC962", // 设置地图点击后的颜色
          //     areaColor: "#FFC962",
          //   },
          // },

          viewControl: viewControl,
        },
        series: [
          {
            // 地图样式
            type: "map3D",
            name: "地图",
            shading: "realistic",
            zlevel: 10,
            realisticMaterial: {
              detailTexture: "mapbg6.png",
            },
            regionHeight: 10,
            map: mapName,

            label: {
              show: true,
              formatter: function (e) {
                if (e.data.value) {
                  return e.name;
                } else {
                  return e.name;
                }
                // console.log(e);
              },
              distance: 50,
              textStyle: {
                color: "#fff",
                fontFamily: "苹方",
                fontSize: 16,
              },
            },

            itemStyle: {
              // color: "#12365b", // 地图板块的颜色
              // color: "rgba(0,225,225,194)",
              opacity: 1, // 图形的不透明度 [ default: 1 ]
              borderWidth: 2.5, // (地图板块间的分隔线)图形描边的宽度。加上描边后可以更清晰的区分每个区域   [ default: 0 ]
              borderColor: "#f4e7d0", // 图形描边的颜色。[ default: #333 ]
            },

            emphasis: {
              label: {
                show: true,
                textStyle: {
                  color: "#fff", // 高亮时标签颜色变为 白色
                  fontSize: 18, // 高亮时标签字体 变大
                },
              },
              itemStyle: {
                color: "rgb(240, 189, 91)",
                // color: "#F0BD5B",
                // areaColor: "#F0BD5B", // 高亮时地图板块颜色改变
              },
            },

            light: {
              main: {
                color: "#296DAF",
                intensity: 1.1,
                shadow: true,
                //shadowQuality: 'high',      // 阴影的质量。可选'low', 'medium', 'high', 'ultra' [ default: 'medium' ]
                alpha: 55, //上下旋转角度
                beta: 30, //左右旋转角度
              },
              ambient: {
                // 全局的环境光设置。
                color: "#fff",
                intensity: 1, // 环境光的强度。[ default: 0.2 ]
              },
            },

            viewControl: viewControl,
            data: this.mapList,
            // .map((e, i) => ({
            //   name: e.name, // 地名
            //   value: e.value, // 随机数值
            //   region: e.region,
            //   height: 6,
            //   emphasis: {
            //     //当鼠标放上去  区域是否显示名称
            //     label: {
            //       show: true,
            //       textStyle: {
            //         color: "#F0BD5B",
            //         fontSize: 16,
            //       },
            //     },
            //     itemStyle: {
            //       color: "#F0BD5B", //当鼠标放上去  区域的颜色
            //     },
            //   },
            // })),
          },
        ],
      };

      myChartmap.setOption(option, true);
      //因为echart地图这个只有一个实例,每次在注册监听前需要把上一次的解绑不然会触发调用多次绑定种的方法
      myChartmap.off("click");
      myChartmap.on("click", function (e) {
        if (
          e.data.region == "500230000000" ||
          e.data.region == "500242000000" ||
          e.data.region == "500240000000" ||
          e.data.region == "500113000000" ||
          e.data.region == "500116000000" ||
          e.data.region == "500119000000" ||
          e.data.region == "500235000000" ||
          e.data.region == "500233000000"
        ) {
          if (e.data.regionLevel > 3) {
            return;
          }
          let regionName = e.data.name;
          if (
            regionName.indexOf("酉阳") != -1 ||
            regionName.indexOf("石柱") != -1 ||
            regionName.indexOf("秀山") != -1 ||
            regionName.indexOf("彭水") != -1
          ) {
            let name1 = regionName.substring(0, 2);
            let name2 = regionName.substr(-1);
            let region = `${name1}${name2}`;
            _this.$store.commit("setRegion", region);
          } else {
            _this.$store.commit("setRegion", e.data.name);
          }
          _this.isShowMap = true;
          _this.regionCode = e.data.region;
          _this.regionName = e.data.name;
          _this.regionLevel = 3;
          _this.getData();
          _this.getMapData();
        }
      });
      setTimeout(() => {
        this.mapShow = true;
      }, 200);
      // 自动滚动dataZoom的代码
      let currentStart = 0; // 初始
      function autoScrollDataZoom() {
        let mapList = _this.mapList;
       
        if (currentStart > mapList.length) {
          currentStart = 0;
        }
        let { name, value } = mapList[currentStart];
        let vs = value.split(",");
        _this.hgzShowInfo = {
          name,
          jiandu: vs[0],
          jiance: vs[1],
          kaiju: vs[2],
        };
        currentStart += 1;
      }
      // 设置定时器来自动滚动dataZoom
      if (this.dituScrollTimer) {
        clearInterval(this.dituScrollTimer);
      }
      this.dituScrollTimer = setInterval(autoScrollDataZoom, 3000); // 1000毫秒（1秒）滚动一次
    },
    */
  },
};
