<template>
  <div class="pop-mina-con">
    <img :src="require('@/assets/images/tanq.png')" alt="" />
    <span class="title">入网主体</span>
    <!-- <div class="basic-info-con">
      <div class="bas-l">
        <img :src="require('@/assets/images/btzs.png')" alt="" />
        <span>基础数据展示</span>
      </div>
    </div> -->
    <div class="detection-con">
      <div class="top-con">
        <div class="top-left">
          <div class="c-mian-con cpjcs">
            <img :src="require('@/assets/images/ztxxzs.png')" alt="" />
            <div class="qiyepfzs">
              <div class="cldbqys">
                <img :src="require('@/assets/images/hgz.png')" alt="" />
                <h1>{{ hegedabiaoList.length }}</h1>
                <h1>
                  承诺达标
                  <h1>企业数</h1>
                </h1>
              </div>
              <div class="qiyepfzs-r">
                <div class="qydys-list">
                  <div class="title-con">
                    <span>企业名称</span>
                    <span>负责人</span>
                    <span>打印张数</span>
                  </div>
                </div>
                <div v-if="hegedabiaoList.length > 0" class="news-list">
                  <div
                    v-for="(item, idx) in hegedabiaoList"
                    :key="idx"
                    class="info-row"
                  >
                    <span>{{ item.subjectName }}</span>
                    <span>{{ item.contacts }}</span>
                    <span class="level">{{ item.printTimes }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click="closeHand" class="close">
      <img :src="require('@/assets/images/guanbi.png')" alt="" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import overlayApi from "@/request/api/overlay.js";
import dict from "@/mixins/index/index.js";
import * as echarts from "echarts";
import moment from "moment";
// import { BigNumber } from "bignumber.js";
export default {
  props: {
    productName: String,
    regionCode: String,
    regionLevel: Number,
    productNameList: Array,
  },
  components: {},
  data() {
    return {
      loading: false,
      ispictureshow: true,
      hgzmrdysl: [],
      recSwiperOptionzs: {
        initialSlide: 0,
        slidesPerView: 6,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperzs: true,
      // 绿码
      recSwiperOptionlm: {
        initialSlide: 0,
        slidesPerView: 6,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperlm: true,
      // 黄码
      recSwiperOptionham: {
        initialSlide: 0,
        slidesPerView: 6,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperham: true,
      //绿码
      recSwiperOptionhm: {
        initialSlide: 0,
        slidesPerView: 6,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperhm: true,

      hegedabiaoList: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.getpromiseQualified();
  },
  mixins: [dict],
  methods: {
    closeHand() {
      this.$emit("close");
    },

    //承诺达标
    async getpromiseQualified() {
      let query = {
        region: this.regionCode,
        regionLevel: this.regionLevel,
      };
      try {
        let res = await overlayApi.subjectInfoShow(query);
        this.hegedabiaoList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pop-mina-con {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    // width: 100%;
    height: 100%;
  }
  .title {
    position: absolute;
    top: 44px;
    left: 108px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #29eaf3;
  }
  .basic-info-con {
    position: absolute;
    top: 118px;
    left: 74px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .bas-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 33px;
        height: 14px;
        margin-right: 5px;
      }
      span {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #07e6ff;
      }
    }
  }
  .detection-con {
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translateX(-50%);
    width: 1439px;
    .cpjcs {
      position: relative;
    }
    .c-mian-con {
      padding: 16px;
      width: 100%;
    }
    .top-con {
      display: flex;
      flex-direction: row;
      align-items: center;
      .top-left {
        width: 100%;
        height: 725px;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;
        .qiyepfzs {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin-top: 34px;
          padding: 0 33px;
          width: 100%;
          .cldbqys {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 73px;
            img {
              width: 100px;
              height: 100px;
              margin-bottom: 10px;
            }
            h1 {
              text-align: center;
              &:nth-child(even) {
                font-weight: 400;
                color: #c1dde5;
                font-size: 36px;
                margin-bottom: 13px;
              }
              &:nth-child(odd) {
                font-weight: 400;
                color: #f1fbfe;
                font-size: 20px;
              }
            }
          }
        }
      }
      .top-ri {
        width: 100%;
        height: 363px;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;
        margin-right: 20px;
        .qiyphz-con {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin-top: 45px;
          .item-row {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
              width: 100px;
              height: 100px;
              margin-bottom: 20px;
            }
            h1 {
              color: #fff;
              &:nth-child(even) {
                font-weight: 400;
                font-size: 40px;
              }
              &:nth-child(odd) {
                font-weight: 400;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .bottom-con {
      width: 1439px;
      height: 364px;
      background-color: rgba($color: #1a3566, $alpha: 0.23);
      border-radius: 20px;
      margin-top: 15px;
      position: relative;
      .qiyezs-list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 7px;
        .item-row {
          .news-list {
            width: 100%;
            box-sizing: border-box;
            height: 250px;
            overflow-y: auto;
          }
          .info-row {
            padding: 5px 13px;
            margin-top: 10px;
            height: 30px;
            text-align: center;
            span {
              font-size: 16px;
              font-weight: 400;
              color: #f7fefe;
            }
            &:nth-child(odd) {
              background: #142951;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
  .close {
    position: absolute;
    right: 74px;
    top: 99px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .bas-r {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    .option-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 18px;

      img {
        height: 11px;
        width: 11px;
        margin-right: 5px;
      }
      span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .qiyepfzs-r {
    display: flex;
    flex-direction: column;
    flex: 1;
    .qydys-list {
      margin-top: 15px;

      .title-con {
        padding: 0 50px;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;

        span {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #18efff;
          text-align: left;
          &:nth-child(1) {
            margin-right: 407px;
          }
          &:nth-child(2) {
            margin-right: 323px;
          }
        }
      }
    }
    .news-list {
      width: 100%;
      box-sizing: border-box;
      height: 550px;
      overflow-y: auto;
    }

    .info-row {
      padding: 5px 45px;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: space-around;
      height: 30px;
      span {
        font-size: 16px;
        font-weight: 400;
        color: #f7fefe;
        text-align: left;
        &:nth-child(1) {
          width: 350px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 127px;
        }
        &:nth-child(2) {
          width: 191px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 190px;
        }
      }

      &:nth-child(odd) {
        background: #142951;
        border-radius: 5px;
      }
    }
  }
  .news-list::-webkit-scrollbar {
    display: none;
  }
}
</style>
