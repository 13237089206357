<template>
  <div class="pop-mina-con">
    <img :src="require('@/assets/images/tanq.png')" alt="" />
    <span class="title">合格证打印数量</span>
    <div class="basic-info-con">
      <div class="bas-l">
        <img :src="require('@/assets/images/btzs.png')" alt="" />
        <span>基础数据展示</span>
      </div>
      <div class="bas-r">
        <div
          v-for="item in optionList"
          :key="item.id"
          @click="switchHand(item)"
          class="option-list"
        >
          <img
            v-if="item.check"
            :src="require('@/assets/images/wxz.png')"
            alt=""
          />
          <img v-else :src="require('@/assets/images/xz.png')" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="chart-con">
      <div class="chart-from-left">
        <div class="c-mian-con">
          <img :src="require('@/assets/images/dystop.png')" alt="" />
          <progress-line :hgzTopTen="hgzTopTen" :type="1" />
        </div>
      </div>
      <div class="chart-from-r">
        <div class="top-con">
          <div class="c-mian-con">
            <img :src="require('@/assets/images/bfdys.png')" alt="" />
            <div class="input-box">
              <a-input
                placeholder="搜索产品小类"
                enter-button
                @search="onSearch"
                v-model="productGenusName"
              />
              <a-button
                @click="handledeterChange"
                class="primary"
                type="primary"
              >
                确定
              </a-button>
            </div>

            <progress-data
              v-if="dashboardShow"
              :dashboardList="dashboardList"
            />
          </div>
        </div>
        <div class="bottom-con">
          <div class="qydys-con">
            <div class="c-mian-con">
              <img :src="require('@/assets/images/qiyedys.png')" alt="" />
              <div class="qydys-list">
                <div class="title-con">
                  <span>地区</span>
                  <span>企业名称</span>
                  <span>合格证打印数</span>
                </div>
                <div v-if="hgzdysList.length > 0" class="news-list">
                  <!-- <swiper
                    :options="recSwiperOptiondy"
                    :not-next-tick="notNextTickOfRecSwiperdy"
                    ref="recSwiperhz"
                    class="swiper-item"
                  >
                    <swiper-slide >
                      
                    </swiper-slide>
                  </swiper> -->
                  <div
                    v-for="(item, idx) in hgzdysList"
                    :key="idx"
                    class="info-row"
                  >
                    <span>{{ item.fullRegionName }}</span>
                    <span>{{ item.subjectName }}</span>
                    <span>{{ item.printCount }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hgzdb-con">
            <div class="c-mian-con">
              <img :src="require('@/assets/images/hegzdb.png')" alt="" />
              <div class="proportion">
                <div class="jzt-con">
                  <span>合格证打印比例</span>
                  <img :src="require('@/assets/images/jzt.png')" alt="" />
                  <div v-if="ratios.length > 0" class="percentage">
                    <h1 v-for="(item, idx) in ratios" :key="idx">
                      {{ item }}%
                    </h1>
                  </div>
                </div>
                <div v-if="productNames.length > 0" class="type-con">
                  <span class="unit">张</span>
                  <div
                    v-for="(item, idx) in productNames"
                    :key="idx"
                    class="type-row"
                  >
                    <span>{{ item.productName }}</span
                    ><span>{{ item.printCount }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click="closeHand" class="close">
      <img :src="require('@/assets/images/guanbi.png')" alt="" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import overlayApi from "@/request/api/overlay.js";
import dict from "@/mixins/index/index.js";
import { Tooltip, Progress } from "ant-design-vue";
import progressData from "@/components/progress-data/index.vue";
// import pieData from "@/components/pie-data/index.vue";
import progressLine from "@/components/line-progress/index.vue";
import moment from "moment";
export default {
  props: {
    regionCode: String,
    regionLevel: Number,
  },
  components: {
    "a-progress": Progress,
    progressData,
    progressLine,
  },
  data() {
    return {
      loading: false,
      productNames: [],
      ratios: [],
      optionList: [
        {
          check: false,
          name: "本月",
          id: 1,
        },
        {
          check: false,
          name: "本周",
          id: 2,
        },
        {
          check: true,
          name: "昨日",
          id: 3,
        },
      ],
      beganTime: "",
      endTime: "",
      dashboardShow: false,
      hgzdysList: [],
      dashboardList: [],
      recSwiperOptiondy: {
        initialSlide: 0,
        slidesPerView: 6,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperdy: true,
      hgzTopTen: [],
      productGenusName: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.endTime = moment().subtract(0.5, "day").format("YYYY-MM-DD");
    this.beganTime = moment(this.endTime).format("YYYY-MM-DD");
    this.queryHand();
    this.getprintCount();
    this.getsubjectInfo();
    this.getTopTen();
  },
  mixins: [dict],
  methods: {
    // 合格证行业类别打印数
    async queryHand() {
      //获取
      this.loading = true;
      try {
        let query = {
          beganTime: `${this.beganTime} 00:00:00`,
          endTime: `${this.endTime} 23:59:59`,
          region: this.regionCode,
          regionLevel: this.regionLevel,
        };
        let res = await overlayApi.hgzhylbdytj(query);
        this.productNames = res.data.map((item) => {
          return {
            productName: item.productName,
            printCount: item.printCount,
          };
        });
        this.ratios = res.data.map((item) => {
          return (Number(item.ratio) * 100).toFixed();
        });
      } catch (error) {
        console.log(error);
      }
    },

    //查询
    switchHand(item) {
      this.optionList.forEach((element) => {
        if (element.check) {
          element.check = false;
        }
      });
      item.check = true;
      this.beganTime = "";
      this.endTime = "";
      if (item.id == 1) {
        //本月
        let start = moment().startOf("month").format("YYYY-MM-DD");
        let end = moment().endOf("month").format("YYYY-MM-DD");
        this.beganTime = start;
        this.endTime = end;
        this.queryHand();
        this.getprintCount();
        this.getsubjectInfo();
        this.getTopTen();
      } else if (item.id == 2) {
        //本周
        let start = moment().startOf("week").format("YYYY-MM-DD");
        let end = moment().endOf("week").format("YYYY-MM-DD");
        this.beganTime = start;
        this.endTime = end;
        this.queryHand();
        this.getprintCount();
        this.getsubjectInfo();
        this.getTopTen();
      } else if (item.id == 3) {
        this.endTime = moment().subtract(1, "day").format("YYYY-MM-DD");
        this.beganTime = moment(this.endTime).format("YYYY-MM-DD");
        this.queryHand();
        this.getprintCount();
        this.getsubjectInfo();
        this.getTopTen();
      }
    },
    //获取企业合格证打印数
    async getsubjectInfo() {
      let params = {
        region: this.regionCode,
        regionLevel: this.regionLevel,
        beganTime: `${this.beganTime} 00:00:00`,
        endTime: `${this.endTime} 23:59:59`,
        productGenusName: this.productGenusName,
      };
      try {
        let response = await overlayApi.subjectInfo(params);
        let list = response.data;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          let d = element.fullRegionName.split(">>");
          element.fullRegionName = d[d.length - 1];
        }

        this.hgzdysList = list;
      } catch (error) {
        console.log(error);
      }
    },
    //获取部分产品合格证打印数
    async getprintCount() {
      let query = {
        beganTime: `${this.beganTime} 00:00:00`,
        endTime: `${this.endTime} 23:59:59`,
        region: this.regionCode,
        regionLevel: this.regionLevel,
        productGenusName: this.productGenusName,
      };
      try {
        let response = await overlayApi.printCount(query);
        let list = response.data.slice(0, 16);
        let total = 0;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          if (index == 0) {
            this.$set(element, "from", "#F0833A");
            this.$set(element, "to", "#F0833A");
          } else if (index == 1) {
            this.$set(element, "from", "#24E298");
            this.$set(element, "to", "#24E298");
          } else if (index == 2) {
            this.$set(element, "from", "#2485E2");
            this.$set(element, "to", "#2485E2");
          } else if (index == 3) {
            this.$set(element, "from", "#02C3DA");
            this.$set(element, "to", "#02C3DA");
          } else if (index == 4) {
            this.$set(element, "from", "#0082FF");
            this.$set(element, "to", "#007FFA");
          } else if (index == 5) {
            this.$set(element, "from", "#C0E224");
            this.$set(element, "to", "#C0E224");
          } else if (index == 6) {
            this.$set(element, "from", "#2485E2");
            this.$set(element, "to", "#2485E2");
          } else if (index == 7) {
            this.$set(element, "from", "#E25E24");
            this.$set(element, "to", "#D75A23");
          } else if (index == 8) {
            this.$set(element, "from", "#BFF03A");
            this.$set(element, "to", "#BDEE3A");
          } else if (index == 9) {
            this.$set(element, "from", "#1F29F1");
            this.$set(element, "to", "#1F29EE");
          } else if (index == 10) {
            this.$set(element, "from", "#5524E2");
            this.$set(element, "to", "#5323DC");
          } else if (index == 11) {
            this.$set(element, "from", "#02C3DA");
            this.$set(element, "to", "#02C3DA");
          } else if (index == 12) {
            this.$set(element, "from", "#0082FF");
            this.$set(element, "to", "#007FFA");
          } else if (index == 13) {
            this.$set(element, "from", "#20C089");
            this.$set(element, "to", "#1DA97F");
          } else if (index == 14) {
            this.$set(element, "from", "#2485E2");
            this.$set(element, "to", "#2485E2");
          } else if (index == 15) {
            this.$set(element, "from", "#B9E93A");
            this.$set(element, "to", "#B7E63A");
          }
          total += element.printCount;
        }
        let dashboardList = list.map((item) => {
          let subjectNames = item.infoVoList.map((d) => {
            return d.subjectName;
          });
          let percent = Number(((item.printCount / total) * 100).toFixed(2));
          percent = parseInt(percent);
          return {
            // value:Number(item.printCount) ,
            name: item.productName,
            percent: percent,
            subjectNames,
            from: item.from,
            to: item.to,
            infoVoList: item.infoVoList,
            subjectNames,
          };
        });
        this.dashboardShow = true;
        this.dashboardList = dashboardList;
      } catch (error) {
        console.log(error);
      }
    },
    // top10
    async getTopTen() {
      try {
        let params = {
          region: this.regionCode,
          regionLevel: this.regionLevel,
          beganTime: `${this.beganTime} 00:00:00`,
          endTime: `${this.endTime} 23:59:59`,
        };
        let res = await overlayApi.hgzdysl(params);
        let total = 0;
        res.data.forEach((item) => {
          try {
            if (item.regionName.length > 4) {
              item.regionName = item.regionName.substring(0, 2);
            }
          } catch (error) {
            console.log("有数据异常", item);
            console.log(error);
          }
          total += item.printCount;
        });
        let list = res.data;
        list = list.slice(0, 10);
        let lineList = list.map((item) => {
          let percent = ((item.printCount / total) * 100).toFixed(2);
          return {
            number: Number(percent),
            name: item.regionName,
            percent: item.printCount,
          };
        });
        this.hgzTopTen = lineList;
      } catch (error) {
        console.log(error);
      }
    },

    closeHand() {
      this.$emit("close");
    },
    onSearch(value) {
      console.log(value);
    },
    //搜索确定
    handledeterChange() {
      this.getsubjectInfo();
      this.getprintCount();
    },
  },
};
</script>

<style scoped lang="scss">
.pop-mina-con {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .title {
    position: absolute;
    top: 44px;
    left: 108px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #29eaf3;
  }
  .basic-info-con {
    position: absolute;
    top: 118px;
    left: 74px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .bas-l {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 33px;
        height: 14px;
        margin-right: 5px;
      }
      span {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #07e6ff;
      }
    }
    .bas-r {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 55px;
      .option-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 18px;

        img {
          height: 11px;
          width: 11px;
          margin-right: 5px;
        }
        span {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .chart-con {
    position: absolute;
    left: 50%;
    top: 172px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    align-items: center;
    .chart-from-left {
      width: 487px;
      margin-right: 19px;
      height: 720px;
      background-color: rgba($color: #1a3566, $alpha: 0.23);
      border-radius: 20px;

      img {
        width: 100%;
        height: 46px;
      }
    }
    .chart-from-r {
      width: 931px;

      .top-con {
        width: 931px;
        height: 363px;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;
        img {
          width: 100%;
          height: 43px;
        }
        .input-box {
          position: absolute;
          top: 5px;
          right: 20px;
          height: 40px;
          display: flex;
          flex-direction: row;
          input {
            background-color: #1a3566;
            border: 1px solid #1a3566;
            border-radius: 4px;
            padding-left: 10px;
            height: 100%;
          }
          .primary {
            margin-left: 20px;
            height: 97%;
          }
        }
      }
      .bottom-con {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .qydys-con {
          width: 560px;
          height: 340px;
          background-color: rgba($color: #1a3566, $alpha: 0.23);
          border-radius: 20px;
          img {
            width: 100%;
            height: 43px;
          }
        }
        .hgzdb-con {
          width: 351px;
          height: 340px;
          background-color: rgba($color: #1a3566, $alpha: 0.23);
          border-radius: 20px;
          margin-left: 20px;
          img {
            width: 100%;
            height: 43px;
          }
          .proportion {
            margin-top: 14px;
            display: flex;
            flex-direction: row;
            .jzt-con {
              display: flex;
              flex-direction: column;
              justify-content: center;
              position: relative;
              img {
                width: 180px;
                height: 215px;
              }
              span {
                margin-left: 27px;
              }
              .percentage {
                position: absolute;
                top: 39px;
                left: 43%;
                transform: translateX(-57%);
                text-align: center;
                h1 {
                  font-size: 18px;
                  font-family: DIN-Bold;
                  font-weight: 400;
                  color: #f7feff;
                  &:nth-child(1) {
                    font-size: 16px;
                    margin-bottom: 25px;
                  }
                  &:nth-child(2) {
                    font-size: 16px;
                    margin-bottom: 26px;
                  }
                  &:nth-child(3) {
                    font-size: 14px;
                    margin-bottom: 30px;
                  }
                  &:nth-child(4) {
                    font-size: 12px;
                  }
                }
              }
            }
            .type-con {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .unit {
                font-size: 14px;
                font-weight: 400;
                color: #e5fdff;
              }
              .type-row {
                margin-top: 21px;
                margin-bottom: 4px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                span {
                  color: #f7feff;
                  font-size: 16px;

                  &:nth-child(1) {
                    margin-right: 13px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 80px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .c-mian-con {
      padding: 16px;
      width: 100%;
      position: relative;
      .qydys-list {
        margin-top: 15px;
        .title-con {
          span {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #18efff;
            text-align: left;
            &:nth-child(1) {
              margin-right: 80px;
              margin-left: 20px;
            }
            &:nth-child(3) {
              float: right;
            }
          }
        }
        .swiper-container {
          position: relative;
          height: 100%;
          .swiper-slide {
            position: relative;
            width: 100%;
            &:nth-child(odd) {
              .info-row {
                background: #142951;
                border-radius: 5px;
              }
            }
          }
        }
        .news-list {
          width: 100%;
          box-sizing: border-box;
          height: 210px;
          margin-top: 20px;
          overflow-y: auto;
        }
        .news-list::-webkit-scrollbar {
          display: none;
        }
        .info-row {
          padding: 5px 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 32px;
          span {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f7fefe;

            &:nth-child(1) {
              margin-right: 55px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 64px;
            }
            &:nth-child(2) {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 250px;
            }
            &:nth-child(3) {
              margin-left: 58px;
            }
          }
          &:nth-child(odd) {
            background: #142951;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .close {
    position: absolute;
    right: 74px;
    top: 99px;
    img {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
