<template>
  <div class="pop-mina-con">
    <img :src="require('@/assets/images/tanq.png')" alt="" />
    <span class="title">认证标识</span>
    <div class="mian-coni">
      <div class="query-con">
        <div class="query-row">
          <div class="option">
            <span>请选择地区</span>
            <a-select
              default-value="请选择地区"
              style="width: 160px"
              @change="dowehandleChange"
            >
              <a-select-option
                v-for="item in countyList"
                :key="item.region"
                :value="item.region"
              >
                {{ item.regionName }}
              </a-select-option>
            </a-select>
          </div>

          <a-button @click="handledeterChange" class="primary" type="primary">
            确定
          </a-button>
        </div>
      </div>
      <div class="qydys-list">
        <div class="qualifications-con">
          <div class="row-con">
            <div class="left">
              <img :src="require('@/assets/images/yuanpan.png')" alt="" />
              <img :src="require('@/assets/images/chanp.png')" alt="" />
            </div>
            <div class="ri">
              <h1>{{ signCount.isGreen }}</h1>
              <h1>绿色食品认证</h1>
            </div>
          </div>
          <div class="row-con">
            <div class="left">
              <img :src="require('@/assets/images/yuanpan.png')" alt="" />
              <img :src="require('@/assets/images/youj.png')" alt="" />
            </div>
            <div class="ri">
              <h1>{{ signCount.isOrganic }}</h1>
              <h1>有机产品认证</h1>
            </div>
          </div>
          <div class="row-con">
            <div class="left">
              <img :src="require('@/assets/images/yuanpan.png')" alt="" />
              <img :src="require('@/assets/images/biaoz.png')" alt="" />
            </div>
            <div class="ri">
              <h1>{{ signCount.isIndication }}</h1>
              <h1>地理标志认证</h1>
            </div>
          </div>
        </div>
        <div class="title-con">
          <span>地区</span>
          <span>企业名称</span>
          <span>所属行业</span>
          <span>资质认证</span>
        </div>

        <div v-if="qiyedata.length > 0" class="list-con">
          <div v-for="(item, idx) in qiyedata" :key="idx" class="info-row">
            <span>{{ item.fullRegionName }}</span>
            <span @click="subjectNameChange(item)" style="cursor: pointer">{{
              item.subjectName
            }}</span>
            <span>{{ item.trade }}</span>
            <div class="qualif">
              <img
                v-if="item.isGreen == 1"
                :src="require('@/assets/images/chanp.png')"
                alt=""
                title="绿色食品认证"
              />
              <img
                v-if="item.isOrganic == 1"
                :src="require('@/assets/images/youj.png')"
                alt=""
                title="有机产品认证"
              />
              <img
                v-if="item.isIndication == 1"
                :src="require('@/assets/images/biaoz.png')"
                alt=""
                title="地理标志认证"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click="closeHand" class="close">
      <img :src="require('@/assets/images/guanbi.png')" alt="" />
    </div>
    <div v-show="isDateilsShow" class="dateils-con">
      <div class="mm-con">
        <img class="bg" :src="require('@/assets/images/tcbg.png')" alt="" />
        <div @click="backChange" class="back">
          <img :src="require('@/assets/images/back.png')" alt="" />
          <span>返回</span>
        </div>
        <div class="product-date">
          <div class="titleo">
            <span>{{ address }}</span>
            <span>{{ subjectName }}</span>
          </div>
          <div class="qualifications-con">
            <div class="row-con">
              <div class="left">
                <img :src="require('@/assets/images/yuanpan.png')" alt="" />
                <img :src="require('@/assets/images/chanp.png')" alt="" />
              </div>
              <div class="ri">
                <h1>{{ productData.greenProductCount }}</h1>
                <h1>绿色食品认证</h1>
              </div>
            </div>
            <div class="row-con">
              <div class="left">
                <img :src="require('@/assets/images/yuanpan.png')" alt="" />
                <img :src="require('@/assets/images/youj.png')" alt="" />
              </div>
              <div class="ri">
                <h1>{{ productData.organicProductCount }}</h1>
                <h1>有机产品认证</h1>
              </div>
            </div>
            <div class="row-con">
              <div class="left">
                <img :src="require('@/assets/images/yuanpan.png')" alt="" />
                <img :src="require('@/assets/images/biaoz.png')" alt="" />
              </div>
              <div class="ri">
                <h1>{{ productData.indicationProductCount }}</h1>
                <h1>地理标志认证</h1>
              </div>
            </div>
          </div>
          <div class="box-con">
            <div class="leftt">
              <img
                class="lbg"
                :src="require('@/assets/images/qx.png')"
                alt=""
              />
              <div class="zs">
                <img :src="require('@/assets/images/zsmc.png')" alt="" />
              </div>
              <span class="name">产品名称</span>
              <div
                class="cp-list"
                :class="item.ct"
                :ref="item.ref"
                v-for="(item, index) in plist"
                :key="`cplist-${index}`"
              >
                <div class="chanpin-con">
                  <img :src="item.img" alt="" />
                  <span>{{ item.name.substring(0, 4) }}</span>
                </div>
              </div>
            </div>
            <div class="rit">
              <div class="zs">
                <img :src="require('@/assets/images/zsmc.png')" alt="" />
              </div>
              <span class="name">资质证书</span>
              <div class="swiper">
                <swiper
                  :options="recSwiperOptionrz"
                  :not-next-tick="notNextTickOfRecSwiperrz"
                  ref="recSwiperjg"
                  class="swiper-item"
                >
                  <swiper-slide v-for="(item, idx) in imgList" :key="idx">
                    <img :src="item" alt="" />
                  </swiper-slide>
                </swiper>
                <div class="swiper-pagination"></div>
                <div class="swiper-button-prev">
                  <img
                    style="width: 28px; height: 28px"
                    :src="require('@/assets/images/houtui.png')"
                    alt=""
                  />
                </div>
                <div class="swiper-button-next">
                  <img
                    style="width: 28px; height: 28px"
                    :src="require('@/assets/images/qianj.png')"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import overlayApi from "@/request/api/overlay.js";
import dict from "@/mixins/index/index.js";
import { Tooltip, Progress } from "ant-design-vue";
export default {
  props: {
    regionCode: String,
    regionLevel: Number,
  },
  components: {
    "a-progress": Progress,
  },
  data() {
    return {
      isDateilsShow: false,
      loading: false,
      recSwiperOptionrz: {
        initialSlide: 0,
        slidesPerView: 1,
        direction: "horizontal",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: true, delay: 3000 },
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      notNextTickOfRecSwiperrz: true,
      qiyedata: [],
      timeBegin: "",
      timeEnd: "",
      ispictureshow: false,
      value: "a",
      list: [],
      countyList: [],
      optionList: [
        {
          check: false,
          name: "本月",
          id: 1,
        },
        {
          check: false,
          name: "本周",
          id: 2,
        },
        {
          check: true,
          name: "昨日",
          id: 3,
        },
      ],
      //两品一标
      signCount: {},
      address: "",
      subjectName: "",
      imgList: [],
      productData: {},
      plist: [
        {
          ref: "f",
          ct: "f",
          name: "-",
          img: require("@/assets/images/tuoy.png"),
        },
        {
          ref: "d",
          ct: "d",
          name: "-",
          img: require("@/assets/images/tuoyuan.png"),
        },
        {
          ref: "g",
          ct: "g",
          name: "-",
          img: require("@/assets/images/tuoyuan.png"),
        },
        {
          ref: "e",
          ct: "e",
          name: "-",
          img: require("@/assets/images/tuoyuan.png"),
        },
        {
          ref: "c",
          ct: "c",
          name: "-",
          img: require("@/assets/images/tuoyuan.png"),
        },
        {
          ref: "h",
          ct: "h",
          name: "-",
          img: require("@/assets/images/tuoyuan.png"),
        },
        {
          ref: "j",
          ct: "j",
          name: "-",
          img: require("@/assets/images/tuoyuan.png"),
        },
        {
          ref: "b",
          ct: "b",
          name: "-",
          img: require("@/assets/images/tuoyuan.png"),
        },

        {
          ref: "i",
          ct: "i",
          name: "-",
          img: require("@/assets/images/tuoyuan.png"),
        },
        {
          ref: "a",
          ct: "a",
          name: "-",
          img: require("@/assets/images/tuoyuan.png"),
        },
        {
          ref: "k",
          ct: "k",
          name: "-",
          img: require("@/assets/images/tuoyuan.png"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  async mounted() {
    await this.dictionary();
    this.queryHand();
    this.getselectSignCount();
  },
  mixins: [dict],
  methods: {
    async dictionary() {
      await Promise.all([this.getTrade(), this.getCity()]);
    },
    //获取城市数据
    async getCity() {
      let params = {
        regionLevel: this.regionLevel + 1,
        parentCode: this.regionCode,
      };
      try {
        let response = await overlayApi.region(params);
        this.countyList = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    onTimeChange(dateString) {
      console.log(dateString);
      this.timeBegin = dateString[0];
      this.timeEnd = dateString[1];
      // this.queryHand();
    },

    //选择区县
    dowehandleChange(value) {
      this.region = value;
    },
    //确定
    handledeterChange() {
      this.queryHand();
      this.getselectSignCount();
    },

    async queryHand() {
      this.loading = true;
      let regionLevel = "";
      for (let index = 0; index < this.countyList.length; index++) {
        let element = this.countyList[index];
        if (element.region == this.region) {
          regionLevel = element.regionLevel;
        }
      }
      try {
        let query = {
          pageNum: 1,
          pageSize: 1000,
        };
        if (this.region) {
          query.region = this.region;
          query.regionLevel = regionLevel;
        } else {
          query.region = this.regionCode;
          query.regionLevel = this.regionLevel;
        }
        // if (this.timeEnd != "") {
        //   query.timeBegin = `${this.timeBegin} 00:00:00`;
        //   query.timeEnd = `${this.timeEnd} 23:59:59`;
        // }
        let res = await overlayApi.selectSign(query);
        // this.pagination = res.data.totalRecords;
        const pagination = { ...this.pagination };
        pagination.total = res.data.totalRecords;
        this.pagination = pagination;
        this.loading = false;
        let list = res.data.list;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          let temp = [];
          let tradeType = element.tradeType.split(",");
          for (let tindex = 0; tindex < this.tradeList.length; tindex++) {
            const telement = this.tradeList[tindex];
            for (let e = 0; e < tradeType.length; e++) {
              const ei = tradeType[e];
              if (ei == telement.value) {
                temp.push(telement.label);
              }
              this.$set(element, "trade", temp.join(","));
            }
          }
        }
        this.qiyedata = list;
      } catch (error) {
        console.log(error);
      }
    },
    //获取两品一标数据
    async getselectSignCount() {
      let regionLevel = "";
      for (let index = 0; index < this.countyList.length; index++) {
        let element = this.countyList[index];
        if (element.region == this.region) {
          regionLevel = element.regionLevel;
        }
      }
      let query = {};
      if (this.region) {
        query.region = this.region;
        query.regionLevel = regionLevel;
      } else {
        query.region = this.regionCode;
        query.regionLevel = this.regionLevel;
      }
      try {
        let response = await overlayApi.selectSignCount(query);
        this.signCount = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    //查询
    switchHand(item) {
      this.optionList.forEach((element) => {
        if (element.check) {
          element.check = false;
        }
      });
      item.check = true;
    },
    closeHand() {
      this.$emit("close");
    },
    backChange() {
      this.isDateilsShow = false;
    },
    //点击企业名称
    async subjectNameChange(item) {
      this.isDateilsShow = true;
      this.address = item.fullRegionName;
      this.subjectName = item.subjectName;
      let eles = [
        this.$refs.f[0],
        this.$refs.d[0],
        this.$refs.g[0],
        this.$refs.e[0],
        this.$refs.c[0],
        this.$refs.h[0],
        this.$refs.j[0],
        this.$refs.b[0],
        this.$refs.i[0],
        this.$refs.a[0],
        this.$refs.k[0],
      ];
      eles.forEach((element) => {
        element.style.display = "none";
      });
      let params = {
        subjectId: item.subjectId,
      };
      try {
        let res = await overlayApi.subjectDetails(params);
        if (!res.data) return;
        this.productData = res.data;
        let { certificateImages, productNames } = res.data;
        if (certificateImages) {
          this.imgList = certificateImages.split(",");
        }
        if (productNames) {
          let temp = productNames.split(",").slice(0, 11);
          for (let index = 0; index < temp.length; index++) {
            const str = temp[index];
            let name = str.replace(/（[^）]+）/g, "");
            this.plist[index].name = name;
            eles[index].style.display = "block";
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pop-mina-con {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .title {
    position: absolute;
    top: 34px;
    left: 74px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #29eaf3;
  }
  .mian-coni {
    position: absolute;
    top: 133px;
    left: 23px;
    .query-con {
      width: 991px;
      height: 64px;
      background: #1a3566;
      // opacity: 0.6;
      display: flex;
      flex-direction: row;
      .query-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0 20px;
        span {
          width: 80px;
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #cbd3d6;
          margin-right: 15px;
        }
        .option {
          margin-right: 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          ::v-deep .ant-select-selection {
            background-color: #1a3566;
            border-radius: 4px;
            border: 1px solid #1e98f5;
            border-top-width: 1.02px;
          }
          ::v-deep .ant-select-selection--single {
            height: 43px;
            color: #00ccff;
          }
          ::v-deep .ant-select-selection__rendered {
            line-height: 40px;
          }
        }
        .primary {
          width: 90px;
          height: 43px;
          background: #1e98f5;
          border-radius: 6px;
          margin-left: 20px;
        }
      }
    }
    .qydys-list {
      margin-top: 15px;
      padding: 20px;
      box-sizing: border-box;
      .qualifications-con {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 40px;
        margin-top: 26px;
        .row-con {
          display: flex;
          flex-direction: row;
          align-items: center;
          .left {
            position: relative;
            img {
              &:nth-child(1) {
              }
              &:nth-child(2) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 41px;
                height: 41px;
              }
            }
          }
          .ri {
            margin-left: 15px;
            h1 {
              color: #e2eeff;
              font-weight: 400;

              &:nth-child(1) {
                font-size: 50px;
              }
              &:nth-child(2) {
                font-size: 16px;
              }
            }
          }
        }
      }
      .title-con {
        margin-bottom: 10px;
        span {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #18efff;
          text-align: left;
          &:nth-child(1) {
            margin-left: 32px;
          }
          &:nth-child(2) {
            margin-left: 165px;
          }
          &:nth-child(3) {
            margin-left: 212px;
          }
          &:nth-child(4) {
            margin-left: 180px;
          }
        }
      }

      .list-con {
        // margin-top: 10px;
        width: 100%;
        height: 332px;
        overflow-y: auto;
        .info-row {
          height: 45px;
          padding-left: 28px;
          display: flex;
          flex-direction: row;
          align-items: center;
          span {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f7fefe;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &:nth-child(1) {
              width: 180px;
              // height: 22px;
              margin-right: 22px;
            }
            &:nth-child(2) {
              width: 210px;
              margin-right: 60px;
            }
            &:nth-child(3) {
              width: 168px;
              margin-right: 82px;
            }
          }
          .qualif {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              width: 30px;
              height: 30px;
              margin-right: 8px;
            }
          }
          &:nth-child(odd) {
            background: #142951;
            border-radius: 5px;
          }
        }

        .swiper-container {
          position: relative;
          height: 100%;
          .swiper-slide {
            position: relative;
            width: 100%;
            // margin-top: 10px;
          }
        }
      }
      .list-con::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .close {
    position: absolute;
    right: 54px;
    top: 76px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .dateils-con {
    position: absolute;
    top: 5px;
    left: 0;
    .mm-con {
      position: relative;
      width: 100%;
      .bg {
        width: 1048px;
        height: 830px;
      }
      .back {
        position: absolute;
        left: 70px;
        top: 53px;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 38px;
          height: 38px;
          margin-right: 13px;
        }
        span {
          font-weight: 500;
          font-size: 18px;
          color: #cfe9ff;
        }
      }
      .product-date {
        position: absolute;
        top: 133px;
        // left: 23px;
        width: 95%;
        padding-left: 70px;
        .titleo {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          width: 93%;
          span {
            font-weight: bold;
            color: #29eaf3;
            font-size: 20px;
          }
        }
        .qualifications-con {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          margin-bottom: 56px;
          margin-top: 80px;
          .row-con {
            display: flex;
            flex-direction: row;
            align-items: center;
            .left {
              position: relative;
              img {
                &:nth-child(1) {
                }
                &:nth-child(2) {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 41px;
                  height: 41px;
                }
              }
            }
            .ri {
              margin-left: 15px;
              h1 {
                color: #e2eeff;
                font-weight: 400;

                &:nth-child(1) {
                  font-size: 50px;
                }
                &:nth-child(2) {
                  font-size: 16px;
                }
              }
            }
          }
        }
        .box-con {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          .leftt {
            width: 476px;
            height: 340px;
            position: relative;
            .lbg {
              width: 100%;
              height: 100%;
            }
            .zs {
              position: absolute;
              top: 20px;
              left: 20px;
            }
            .name {
              position: absolute;
              top: 20px;
              left: 59px;
              font-weight: 400;
              font-size: 20px;
              color: #f7fefe;
            }
            .cp-list {
              position: absolute;
              cursor: pointer;
              &:hover {
                animation: unset;
              }
              .chanpin-con {
                position: relative;
                img {
                  width: 68px;
                  height: 68px;
                }
                span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  font-weight: bold;
                  font-size: 15px;
                  color: #8ec7f7;
                  text-align: center;
                }
              }
            }
            .a {
              top: 127px;
              left: 10px;
              animation: float 3s ease-in-out infinite;
            }
            .b {
              left: 30px;
              bottom: 60px;
              animation: float 2s ease-in-out infinite;
            }
            .c {
              left: 90px;
              top: 140px;
              animation: float 1.5s ease-in-out infinite;
            }
            .d {
              left: 150px;
              top: 80px;
              animation: float 4s ease-in-out infinite;
            }
            .e {
              left: 150px;
              top: 220px;
              animation: float 2s ease-in-out infinite;
            }
            .f {
              left: 200px;
              top: 130px;
              .chanpin-con {
                img {
                  width: 88px;
                  height: 88px;
                }
                span {
                  font-size: 20px;
                }
              }
              animation: float 3s ease-in-out infinite;
            }
            .g {
              bottom: 60px;
              left: 240px;
              animation: float 5s ease-in-out infinite;
            }
            .h {
              right: 110px;
              top: 100px;
              animation: float 4s ease-in-out infinite;
            }
            .i {
              right: 30px;
              top: 120px;
              animation: float 2s ease-in-out infinite;
            }
            .j {
              right: 90px;
              top: 180px;
              animation: float 2.5s ease-in-out infinite;
            }
            .k {
              right: 20px;
              top: 210px;
              animation: float 3.5s ease-in-out infinite;
            }
          }
          .rit {
            width: 385px;
            height: 340px;
            background-color: rgba($color: #1a3566, $alpha: 0.2);
            border-radius: 20px;
            margin-left: 20px;
            position: relative;
            .zs {
              padding-top: 25px;
              padding-left: 12px;
            }
            .name {
              position: absolute;
              top: 20px;
              left: 50px;
              font-weight: 400;
              font-size: 20px;
              color: #f7fefe;
            }
            .swiper {
              margin-top: 15px;
              .swiper-item {
                width: 156px;
                height: 220px;
              }
              .swiper-button-next {
                // background-image: url("/assets/images/houtui.png");
                background-image: none;
                margin-top: 10px;
              }
              .swiper-button-prev {
                // background-image: url("/assets/images/qianj.png");
                background-image: none;
                margin-top: 10px;
              }
              .swiper-pagination {
                left: 50%;
                transform: translateX(-50%);
                margin-top: 10px;
                ::v-deep .swiper-pagination-bullet {
                  background-color: #1c4596;
                  width: 10px;
                  height: 10px;
                  margin-right: 5px;
                  opacity: 1;
                }
                ::v-deep .swiper-pagination-bullet-active {
                  background-color: #fff;
                }
              }

              img {
                width: 156px;
                height: 220px;
              }
            }
          }
        }
      }
    }
  }
}
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
