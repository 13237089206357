import dictApi from "@/request/api/dict";

export default {
  data() {
    return {
      //主体类别列表
      subjectList: [],
      // 生产范围列表
      businessList: [],
      //所属行业
      tradeList: [],
      //审核状态
      stateList: [],
      //类别列表
      categoryList: [],
      //状态
      cstateList: [],
      //产品单位
      unitList: [],
      //承诺依据
      basisList: [],
      //承诺内容
      contentList: [],
      //资质类别
      quaTypeList: [],
      //证书状态
      certStateList: [],
      //地区级别
      regionLevelList: [],
      //人员分组
      workGroupList: [],
      sysist: [],
      batchsList: [],
      newsList: [],
      userTypeList: [],
      userStateList: [],
      //任务来源
      taskSourceList: [],
      //任务类别
      tasktypeList: [],
      checkWayList: [], //检测方式
      checkItemList: [], //检测项目
      weigntunitList: [],
      basis: "",
      fileTypeList: [],
    };
  },
  created() {},
  methods: {
    //获取主体类别
    getsubject() {
      this.listLoading = true;
      let params = {
        dictType: "subject_type",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.subjectList = response.data;
      });
    },
    //获取生产范围
    getBusiness() {
      this.listLoading = true;
      let params = {
        dictType: "business_type",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.businessList = response.data;
      });
    },
    //获取所属行业
    async getTrade() {
      let params = {
        dictType: "trade_type",
      };
      try {
        let response = await dictApi.dict(params);
        this.tradeList = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    //获取审核状态
    getState() {
      this.listLoading = true;
      let params = {
        dictType: "check_state",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.stateList = response.data;
      });
    },
    //获取状态
    getstates() {
      this.listLoading = true;
      let params = {
        dictType: "is_use",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.cstateList = response.data;
      });
    },
    //获取产品单位
    getunit() {
      this.listLoading = true;
      let params = {
        dictType: "count_unit",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.unitList = response.data;
      });
    },

    //获取承诺依据
    getaccord() {
      this.listLoading = true;
      let params = {
        dictType: "commitment_basis",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.basisList = response.data;
      });
    },
    //获取承诺内容
    getContent() {
      this.listLoading = true;
      let params = {
        dictType: "commitment_content",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.contentList = response.data;
      });
    },
    //获取资质类别
    getquaType(remark) {
      this.listLoading = true;
      let params = {
        dictType: "cert_type",
      };
      if (remark) {
        params.remark = remark;
      }
      dict(params).then((response) => {
        this.listLoading = false;
        this.quaTypeList = response.data;
      });
    },
    //证书过期状态
    getcertState() {
      this.listLoading = true;
      let params = {
        dictType: "cert_state",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.certStateList = response.data;
      });
    },
    //地区级别
    getRegionLevel() {
      this.listLoading = true;
      let params = {
        dictType: "region_level",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.regionLevelList = response.data;
      });
    },
    //人员分组
    async getWorkGroup() {
      this.listLoading = true;
      let params = {
        dictType: "supervise_group",
      };
      try {
        let res = await dict(params);
        this.workGroupList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    //是否展示
    getsys() {
      this.listLoading = true;
      let params = {
        dictType: "sys_yes_no",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.sysist = response.data;
      });
    },
    //批次列表
    getBatch() {
      this.listLoading = true;
      let params = {
        dictType: "record_type",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.batchsList = response.data;
      });
    },
    //获取资讯类别
    getnews() {
      this.listLoading = true;
      let params = {
        dictType: "news_class",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.newsList = response.data;
      });
    },
    //用户类型
    getUserType() {
      this.listLoading = true;
      let params = {
        dictType: "user_type",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.userTypeList = response.data;
      });
    },
    //用户相关的状态
    getUserState() {
      this.listLoading = true;
      let params = {
        dictType: "sys_normal_disable",
      };
      dict(params).then((response) => {
        this.listLoading = false;
        this.userStateList = response.data;
      });
    },
    getTaskSource() {
      let params = {
        dictType: "task_source",
      };
      dict(params).then((response) => {
        this.taskSourceList = response.data;
      });
    },
    gettasktype() {
      let params = {
        dictType: "task_type",
      };
      dict(params).then((response) => {
        this.tasktypeList = response.data;
      });
    },
    async getcheckway() {
      let params = {
        dictType: "check_way",
      };
      try {
        let response = await dictApi.dict(params);
        this.checkWayList = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getcheckItem() {
      let params = {
        dictType: "check_item",
      };
      try {
        let response = await dictApi.dict(params);
        this.checkItemList = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getweigntunit() {
      let params = {
        dictType: "weight_unit",
      };
      try {
        let response = await dictApi.dict(params);
        this.weigntunitList = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getoperatorId(id) {
      try {
        let params = {
          productBatchId: id,
        };
        let response = await taskAnd(params);
        this.basis = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getfeilType() {
      try {
        let params = {
          dictType: "file_type",
        };
        let response = await dict(params);
        this.fileTypeList = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
