import http from "../http.js";
const api = {
  /** 字典 */
  dict: function (params = {}) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/common/getDict`,
      data: params,
    });
  },
};

export default api;
