<template>
  <div class="pop-mina-con">
    <img :src="require('@/assets/images/jdzxjc.png')" alt="" />
    <span class="title">豇豆专项检测统计</span>
    <div class="cowpea-con">
      <div class="cowpea-top">
        <div class="testing-con">
          <div class="title-con">
            <img :src="require('@/assets/images/jtjcj.png')" alt="" />
            <span>豇豆专项检测</span>
          </div>
          <div class="echarts-con">
            <div class="line-con" id="zxpie"></div>
          </div>
        </div>
        <div class="sub-info">
          <div class="title-con">
            <img :src="require('@/assets/images/jtjcj.png')" alt="" />
            <span>豇豆企业主体信息</span>
          </div>
          <div class="qiye-mod">
            <img
              class="dizuo"
              :src="require('@/assets/images/xxdb.png')"
              alt=""
            />
            <div class="qiye-mod-icon">
              <div class="mod-data-l">
                <div class="info-con">
                  <img
                    class="dip"
                    :src="require('@/assets/images/dizuo.png')"
                    alt=""
                  />
                  <div class="tolal-data">
                    <span class="qiye">
                      <countTo
                        :startVal="startVal"
                        :endVal="leftOne.totalSubjectNum"
                        :duration="duration"
                      ></countTo>
                      <span style="margin-left: -10px">家</span></span
                    >
                    <h1>入网主体</h1>
                    <img :src="require('@/assets/images/qy.png')" alt="" />
                  </div>
                  <!-- <div class="see-con">
                    <span>查看</span>
                    <a-icon type="right" />
                  </div> -->
                </div>
                <div class="info-con">
                  <img
                    class="dip"
                    :src="require('@/assets/images/dizuo.png')"
                    alt=""
                  />
                  <div class="tolal-data">
                    <span class="lp">
                      <countTo
                        :startVal="startVal"
                        :endVal="leftOne.certSubjectNum"
                        :duration="duration"
                      >
                      </countTo>
                      <span style="margin-left: -10px">家</span>
                    </span>
                    <h1>两品一标</h1>
                    <img :src="require('@/assets/images/lpyb.png')" alt="" />
                  </div>
                  <!-- <div class="see-con">
                    <span>查看</span>
                    <a-icon type="right" />
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hegez-top">
          <div class="title-con">
            <img :src="require('@/assets/images/jtjcj.png')" alt="" />
            <span>豇豆合格证打印top</span>
          </div>
          <div class="top-line">
            <progress-line :hgzTopTen="hgzTopTen" :type="1" />
          </div>
        </div>
      </div>
      <div class="cowpea-top">
        <div class="entity-con">
          <div class="title-con">
            <img :src="require('@/assets/images/jtjcj.png')" alt="" />
            <span class="title">豇豆企业主体</span>
            <div @click="seeHand" class="hegez-ri">
              <h1></h1>
              <span>查看更多</span>
            </div>
          </div>
          <div class="entity-rows">
            <div class="entity-title">
              <span>地区</span>
              <span>企业名称</span>
            </div>
            <div class="list-con">
              <div
                v-for="item in entityList"
                :key="item.subjectId"
                class="entity-list"
              >
                <h1>{{ item.regionName }}</h1>
                <h1>{{ item.subjectName }}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="hgzdy-con">
          <div class="title-con">
            <img :src="require('@/assets/images/jtjcj.png')" alt="" />
            <span>豇豆合格证打印</span>
          </div>
          <div class="line-echarts" id="lineEcharts"></div>
        </div>
      </div>
    </div>
    <div @click="closeddHand" class="close">
      <img :src="require('@/assets/images/guanbi.png')" alt="" />
    </div>
    <div v-if="qiyeShow" class="modal-con">
      <subject-list
        :key="key"
        :regionCode="regionCode"
        :regionLevel="regionLevel"
        @close="handleqiCancel"
      />
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import overlayApi from "@/request/api/overlay.js";
import { mapGetters } from "vuex";
import progressLine from "@/components/line-progress/index.vue";
import countTo from "vue-count-to";
import subjectList from "@/components/subjectList/index.vue";

export default {
  props: {
    regionCode: String,
    regionLevel: Number,
  },
  components: {
    progressLine,
    countTo,
    subjectList,
  },
  data() {
    return {
      checkCardSum: 0,
      checkSum: 0,
      checkTemporarySum: 0,
      checkAllSum: 0,
      subjectId: null,
      jinxin: {},
      // 初始值
      startVal: 0,
      // 需要滚动的时间
      duration: 5000,
      leftOne: {
        totalSubjectNum: 0,
        certSubjectNum: 0,
        certificateNum: 0,
        batchNum: 0,
      },
      hgzTopTen: [],
      entityList: [],
      productGenusId: "04a95b431a3a11eea7f800163e044a85",
      printTime: [],
      printWeekCount: [],
      qiyeShow: false,
      key: new Date().getTime(),
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.getpecial();
    this.getspecialDetection();
    this.getTopTen();
    this.getSubInfor();
    this.getCerPrintData();
    console.log(this.regionCode);
  },
  methods: {
    async getpecial() {
      let params = {
        regionCode: this.regionCode,
      };
      if (this.regionCode == "500000000000") {
        params.regionCode = "";
      }
      try {
        let res = await overlayApi.specialCount(params);
        let { checkCardSum, checkSum, checkTemporarySum, checkAllSum } =
          res.data;
        this.checkCardSum = checkCardSum;
        this.checkSum = checkSum;
        this.checkTemporarySum = checkTemporarySum;
        this.checkAllSum = checkAllSum;
        await this.$nextTick();
        this.getJdZxjc();
      } catch (error) {
        console.log(error);
      }
    },
    async getspecialDetection() {
      let params = {
        regionCode: this.regionCode,
      };
      if (this.regionCode == "500000000000") {
        params.regionCode = "";
      }
      try {
        let res = await overlayApi.specialDetection(params);
        this.entityList = res.data.list;
      } catch (error) {
        console.log(error);
      }
    },
    getJdZxjc() {
      var chartDom = document.getElementById("zxpie");
      var myChart = echarts.init(chartDom);
      let option = {
        grid: {
          left: "5%",
          right: "0",
          bottom: "10%",
        },
        tooltip: {
          trigger: "item",
        },

        title: {
          text: "总数",
          subtext: this.checkAllSum,
          color: "#fff",
          x: "center",
          y: "center",
          textStyle: {
            // fontWeight: "normal",
            fontSize: 20,
            color: "#99BBF0",
            fontWeight: "bold",
          },
          subtextStyle: {
            color: "#fff",
            fontSize: 32,
            fontWeight: "bold",
          },
        },

        series: [
          {
            name: "",
            type: "pie",
            radius: [60, 110],
            center: ["50%", "53%"],
            // radius: ["35%", "60%"],
            // roseType: "area",
            color: ["#20A7FD", "#01F0B7", "#263DFF"],
            avoidLabelOverlap: false,
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: "bold",
              },
            },
            itemStyle: {},

            data: [
              { value: this.checkTemporarySum, name: "临时抽检" },
              { value: this.checkSum, name: "机构抽检" },
              { value: this.checkCardSum, name: "胶体金" },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
    closeddHand() {
      this.$emit("close");
    },
    //合格证打印top
    async getTopTen() {
      try {
        let params = {
          region: this.regionCode,
          regionLevel: this.regionLevel,
          productGenusId: this.productGenusId,
        };
        let res = await overlayApi.hgzdysl(params);
        let list = res.data.slice(0, 10);
        let total = 0;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          total += element.printCount;
        }

        let lineList = list.map((item) => {
          let percent = ((item.printCount / total) * 100).toFixed(2);
          console.log("percent", percent);
          return {
            number: Number(percent),
            name: item.regionName,
            percent: item.printCount,
          };
        });
        this.hgzTopTen = lineList;
      } catch (error) {
        console.log(error);
      }
    },
    //主体信息
    async getSubInfor() {
      try {
        let params = {
          region: this.regionCode,
          regionLevel: this.regionLevel,
          productGenusId: this.productGenusId,
        };
        let res = await overlayApi.getBigScreen(params);
        let { leftOne } = res.data;
        this.leftOne.totalSubjectNum = leftOne.totalSubjectNum;
        this.leftOne.certSubjectNum = leftOne.certSubjectNum;
      } catch (error) {
        console.log(error);
      }
    },
    //合格证打印
    async getCerPrintData() {
      let params = {
        region: this.regionCode,
        regionLevel: this.regionLevel,
      };
      try {
        let res = await overlayApi.cowpeaPrint(params);
        let printList = res.data;
        this.printTime = printList.map((item) => {
          return item.beginDayOfWeek + " ~\n" + item.endDayOfWeek;
        });
        this.printWeekCount = printList.map((item) => {
          return item.weekCount;
        });
        await this.$nextTick();
        this.printLineEcharts();
      } catch (error) {
        console.log(error);
      }
    },
    printLineEcharts() {
      var chartDom = document.getElementById("lineEcharts");
      var myChart = echarts.init(chartDom);
      let option = {
        tooltip: {},
        grid: {
          top: "8%",
          left: "2%",
          right: "4%",
          bottom: "8%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: "#103EAB",
              },
            },
            axisLabel: {
              formatter: function (params) {
                var newParamsName = "";
                var paramsNameNumber = params.length;
                var provideNumber = 12; //一行显示几个字
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = "";
                    var start = p * provideNumber;
                    var end = start + provideNumber;
                    if (p == rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {
                      tempStr = params.substring(start, end) + "\n";
                    }
                    newParamsName += tempStr;
                  }
                } else {
                  newParamsName = params;
                }
                return newParamsName;
              },
              //坐标轴刻度标签的相关设置
              textStyle: {
                color: "#fff",
                width: 10,
                margin: 10,
                overflow: "breakAll",
              },
            },
            axisTick: { show: true },
            data: this.printTime,
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            max: 100,
            splitLine: {
              show: false,
              lineStyle: {
                color: "#233e64",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#103EAB",
              },
            },
            axisLabel: {
              margin: 20,
              textStyle: {
                color: "#fff",
              },
            },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            smooth: true, //是否平滑曲线显示
            symbolSize: 0,
            // showAllSymbol: true,
            lineStyle: {
              normal: {
                color: "#02B793", // 线条颜色
                width: 4,
              },
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: "rgba(2,183,148,0.3)" },
                    { offset: 0.7, color: "rgba(2,183,147,0)" },
                  ],
                  false
                ),

                // shadowColor: "rgba(2,183,148,0.5)", //阴影颜色
                shadowBlur: 10, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
            data: this.printWeekCount,
          },
        ],
      };
      myChart.setOption(option);
    },
    seeHand() {
      this.qiyeShow = true;
    },
    handleqiCancel() {
      this.qiyeShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pop-mina-con {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    // width: 100%;
    height: 100%;
  }
  .title {
    position: absolute;
    top: 44px;
    left: 108px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #29eaf3;
  }
  .close {
    position: absolute;
    right: 74px;
    top: 99px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .cowpea-con {
    position: absolute;
    top: 140px;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .cowpea-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
      .title-con {
        position: relative;
        .title {
          position: absolute;
          top: -10px;
          left: 40px;
          font-weight: 400;
          font-size: 20px;
          color: #f7fefe;
        }
        .hegez-ri {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: absolute;
          top: -10px;
          right: 40px;
          h1 {
            width: 10px;
            height: 10px;
            background: linear-gradient(180deg, #0fd0e2 0%, #081430 100%);
            border-radius: 2px;
            margin-right: 2px;
            margin-top: 3px;
          }
          span {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #56d7ff;
          }
        }
      }
      .testing-con {
        width: 487px;
        height: 421px;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;
        padding: 10px 15px;
        .echarts-con {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .line-con {
            width: 380px;
            height: 380px;
          }
        }
      }
      .sub-info {
        width: 681px;
        height: 421px;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;
        padding: 10px 15px;
        margin-left: 20px;
        margin-right: 20px;
        .qiye-mod {
          position: relative;
          margin-top: 180px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          .dizuo {
            width: 530px;
            height: 57.5px;
            margin-left: 13px;
          }
          .qiye-mod-icon {
            position: absolute;
            top: -40px;
            left: 12px;
            width: 100%;
            .mod-data-l {
              position: relative;
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
              width: 100%;
              padding: 0 35px;
              .info-con {
                position: relative;
                .tolal-data {
                  position: absolute;
                  top: -76px;
                  left: 50%;
                  transform: translateX(-50%);
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  img {
                    width: 50px;
                    height: 50px;
                  }
                  span {
                    white-space: nowrap;
                  }
                  .qiye {
                    font-family: DIN-Bold;
                    font-weight: 400;
                    color: #56d7ff;
                    span {
                      &:nth-child(1) {
                        font-size: 36px;
                      }
                      font-size: 16px;
                      font-family: Source Han Sans CN;
                    }
                  }
                  .lp {
                    font-size: 36px;
                    font-family: DIN-Bold;
                    font-weight: 400;
                    color: #1cf8a3;
                    span {
                      &:nth-child(1) {
                        font-size: 36px;
                      }
                      font-size: 16px;
                    }
                  }
                  .cluo {
                    font-size: 36px;
                    font-family: DIN-Bold;
                    font-weight: 400;
                    color: #ef9f14;
                    span {
                      &:nth-child(1) {
                        font-size: 36px;
                      }
                      font-size: 16px;
                    }
                  }
                  .scp {
                    font-size: 36px;
                    font-family: DIN-Bold;
                    font-weight: 400;
                    color: #1c81ff;
                    span {
                      &:nth-child(1) {
                        font-size: 36px;
                      }
                      font-size: 16px;
                    }
                  }
                  h1 {
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #d8f6ff;
                    white-space: nowrap;
                  }
                }
                .see-con {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  top: 58px;
                  left: 54%;
                  transform: translateX(-46%);
                  cursor: pointer;
                  span {
                    color: #ebf3ff;
                    margin-right: 2px;
                  }
                }
              }
              .dip {
                width: 114px;
                height: 87px;
              }
            }
          }
        }
      }
      .hegez-top {
        width: 520px;
        height: 421px;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;
        padding: 10px 15px;
      }
      .entity-con {
        width: 487px;
        height: 320px;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;
        padding: 10px 15px;
        margin-right: 20px;
        .entity-rows {
          .entity-title {
            span {
              font-weight: 400;
              font-size: 16px;
              color: #18efff;
              &:nth-child(1) {
                margin-left: 20px;
                margin-right: 153px;
              }
            }
          }
          .list-con {
            height: 240px;
            overflow-y: auto;
            .entity-list {
              padding: 7px 10px;
              margin-top: 7px;
              display: flex;
              flex-direction: row;
              align-items: center;
              h1 {
                font-weight: 400;
                font-size: 16px;
                color: #f7fefe;
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                &:nth-child(1) {
                  width: 151px;
                }
                &:nth-child(2) {
                  width: 231px;
                  margin-left: 33px;
                }
              }
              &:nth-child(odd) {
                background-color: rgba($color: #1a3566, $alpha: 0.23);
              }
            }
          }
          .list-con::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .hgzdy-con {
        width: 1218px;
        height: 320px;
        background-color: rgba($color: #1a3566, $alpha: 0.23);
        border-radius: 20px;
        padding: 10px;
        .line-echarts {
          width: 100%;
          height: 260px;
        }
      }
    }
  }
  .top-line {
    height: 350px;
    overflow-y: auto;
  }
  .top-line::-webkit-scrollbar {
    display: none;
  }
  .modal-con {
    width: 1582px;
    height: 984px;
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -51%);
    z-index: 1001;
  }
}
</style>
