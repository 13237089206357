<template>
  <div class="pop-mina-con">
    <img :src="require('@/assets/images/aqjg.png')" alt="" />
    <span class="title">农产品质量安全监管</span>

    <div class="chart-con">
      <div id="container" class="mapin" />
    </div>
    <div v-if="personList.length != 0 || personalInfo" class="chart-from-r">
      <div class="top-con">
        <div class="c-mian-con">
          <img :src="require('@/assets/images/jgyzs.png')" alt="" />
          <span @click="backHand" v-if="level != 0" class="back">返 回</span>
          <div class="current-con">
            <span>当前地区：</span>
            <span>{{ fullRegionName }}</span>
          </div>
          <div v-if="isshuxinShow">
            <div class="title-con">
              <span>姓名</span>
              <span>职务</span>
              <span>所属单位</span>
              <span>联系电话</span>
            </div>

            <div v-if="personList.length > 0" class="list-con">
              <div
                v-for="(item, idx) in personList"
                :key="idx"
                class="info-row"
              >
                <span class="nickName">{{ item.nickName }}</span>
                <span class="supervisePost">{{ item.supervisePost }}</span>
                <span class="pointName">{{ item.organization }}</span>
                <span class="showPhone">{{ item.showPhone }}</span>
              </div>
            </div>
          </div>
          <div v-else class="personnel-info">
            <div class="main-info">
              <img :src="personalInfo.headUrl" alt="" />
              <div class="item-inf">
                <h1>姓名：{{ personalInfo.name }}</h1>
                <h1>联系电话：{{ personalInfo.phone }}</h1>
                <h1 v-if="personalInfo.workType == 1">岗位：监管员</h1>
                <h1 v-if="personalInfo.workType == 2">岗位：协管员</h1>
              </div>
            </div>
            <div class="other-info">
              <h1 v-if="personalInfo.workGroup == 1">人员分组：组长</h1>
              <h1 v-if="personalInfo.workGroup == 2">人员分组：副组长</h1>
              <h1 v-if="personalInfo.workGroup == 3">人员分组：组员</h1>
              <!-- <h1>监管机构名称：{{ personalInfo.pointName }}</h1> -->
              <h1>工作单位:{{ personalInfo.organization }}</h1>
              <h1>职务：{{ personalInfo.supervisePost }}</h1>
              <h1>人员职责：{{ personalInfo.superviseDuty }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="wghData.length != 0" class="chart-from-l">
      <div class="top-con">
        <div class="c-mian-con">
          <img :src="require('@/assets/images/jgyzs.png')" alt="" />
        </div>
        <div class="wgh-con">
          <div id="chart-panel" class="content-box"></div>
        </div>
      </div>
    </div>
    <div @click="closeHand" class="close">
      <img :src="require('@/assets/images/guanbi.png')" alt="" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import overlayApi from "@/request/api/overlay.js";
import axios from "axios";
import * as echarts from "echarts";

export default {
  name: "Regulatory",
  components: {},
  props: {
    regionCode: String,
    regionLevel: Number,
    regionName: String,
    region: String,
  },
  data() {
    return {
      personList: [],
      recSwiperOptionwgh: {
        initialSlide: 0,
        slidesPerView: 6,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperwgh: true,
      imgurl: require(`@/assets/images/dingwei.png`),
      level: 0,
      code: 0,
      swiperRefreshKey: 1695650495,
      wghData: [],
      fullRegionName: "",
      isshuxinShow: true,
      personalInfo: null,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  async mounted() {
    this.initMap();
    this.humanList();
    if (this.level == 0) {
      //说明是没有点击地图
      if (this.regionLevel == 2) {
        this.fullRegionName = this.regionName;
      } else {
        this.fullRegionName = `重庆市>>${this.regionName}`;
      }
    }
    this.getmeshUserTree();
  },
  methods: {
    async humanList() {
      let params = {
        pageSize: 30,
        pageNum: 1,
      };
      if (this.code != 0) {
        params.region = this.code;
      } else {
        params.region = this.regionCode;
      }
      try {
        let res = await overlayApi.meshUser(params);
        let item = res.data.list;
        this.personList = item;
        await this.$nextTick();
        this.swiperRefreshKey += 1;
      } catch (error) {
        console.log(error);
      }
    },
    async getmeshUserTree() {
      let params = {};
      if (this.code != 0) {
        params.region = this.code;
      } else {
        params.region = this.regionCode;
      }
      try {
        let res = await overlayApi.meshUserTree(params);
        this.wghData = await this.handleDydata(res.data);
        await this.$nextTick();
        this.getPanel();
      } catch (error) {
        console.log(error);
      }
    },
    async handleDydata(list) {
      let resultList = [];
      for (let index = 0; index < list.length; index++) {
        const item = list[index];
        let headUrl = "";
        try {
          if (!item.userPic) {
            let upurl = require("@/assets/images/morenimg.png");
            headUrl = await this.imgToBase64(upurl);
          } else {
            headUrl = item.userPic;
            // headUrl = await this.imgToBase64(upurl);
          }
        } catch (error) {
          console.log("headUrl assign error ", error);
        }

        let obj = {
          name: item.nickName,
          headUrl: headUrl,
          phone: item.phone,
          // pointName: item.pointName,
          organization: item.organization,
          workGroup: item.workGroup,
          workType: item.workType,
          userName: item.userName,
          superviseDuty: item.superviseDuty,
          supervisePost: item.supervisePost,
          label: {
            position: 0,
            align: "center",
            padding: [10, 20],
            fontWeight: "bold",
            formatter: function (e) {
              return ["{img|}", "{name|".concat(e.data.name, "}")].join("\n");
            },
            rich: {
              img: {
                backgroundColor: {
                  image: headUrl,
                },
                width: 90,
                height: 100,
              },
              name: {
                color: "#333",
                fontSize: 14,
                fontWeight: 600,
                verticalAlign: "middle",
                backgroundColor: "#ddd",
                width: 90,
                padding: [5, 0],
              },
              // position: {
              //   color: "#333",
              //   fontSize: 12,
              //   verticalAlign: "middle",
              //   backgroundColor: "#ddd",
              //   width: 110,
              //   padding: [5, 0],
              // },
            },
          },
        };
        if (item.childList) {
          let lt = await this.handleDydata(item.childList);
          obj.children = lt;
        }

        resultList.push(obj);
      }
      return resultList;
    },
    getPanel() {
      let _this = this;
      let series = [
        {
          type: "tree",
          orient: "TB",
          name: "人员监管网格",
          edgeShape: "polyline",
          data: this.wghData,
          width: 700,
          height: "55%",
          top: "15%",
          left: "center",
          bottom: "15%",
          // symbolSize: 1,
          roam: true,
          lineStyle: {
            color: "#00eaff",
            width: 2,
            curveness: 0.2,
          },
          expandAndCollapse: true,
        },
      ];

      var chartDom = document.getElementById("chart-panel");
      try {
        this.barChartInstance.clear();
      } catch (error) {
        console.log(error);
      }
      this.barChartInstance = echarts.init(chartDom);
      var myChart = this.barChartInstance;
      let option = {
        tooltip: {
          show: "false",
          trigger: "item",
          formatter: function (e) {
            var t = e.data;
            return `${t.name}`;
          },
        },

        series,
      };
      myChart.setOption(option);
      myChart.off("click");
      myChart.on("click", treeNodeclick);
      function treeNodeclick(params) {
        console.log("treeNodeclick params", params.data);
        _this.isshuxinShow = false;
        _this.personalInfo = params.data;
      }
    },
    closeHand() {
      this.$emit("close");
    },
    imgToBase64(e) {
      var t = document.createElement("canvas"),
        a = t.getContext("2d"),
        o = new Image();
      return (
        (o.crossOrigin = "Anonymous"),
        (o.src = e),
        new Promise(function (e, s) {
          o.onload = function () {
            (t.width = 110), (t.height = 150), a.drawImage(o, 0, 0, 110, 150);
            var s = t.toDataURL("image/png");
            (t = null), e(s);
          };
        })
      );
    },
    async initMap() {
      window._AMapSecurityConfig = {
        securityJsCode: "cedd8498e9c81d01c23edd92e7b4f7a7",
      };
      let params = {
        pageNum: 1,
        pageSize: 38,
      };

      if (this.level != 0) {
        params.regionLevel = this.level + 1;
        if (this.level == 4) {
          params.townCode = this.code;
        } else if (this.level == 3) {
          params.districtCode = this.code;
        } else if (this.level == 2) {
          params.cityCode = this.code;
        }
      } else {
        params.regionLevel = this.regionLevel + 1;
        if (this.regionLevel == 4) {
          params.townCode = this.regionCode;
        } else if (this.regionLevel == 3) {
          params.districtCode = this.regionCode;
        } else if (this.regionLevel == 2) {
          params.cityCode = this.regionCode;
        }
      }
      let latitude = "";
      let longitude = "";
      let regionCode = "";
      if (this.code != 0) {
        regionCode = this.code;
      } else {
        regionCode = this.regionCode;
      }
      if (regionCode == "500113000000") {
        //巴南
        latitude = 106.540603;
        longitude = 29.402348;
      } else if (regionCode == "500119000000") {
        //南川
        latitude = 107.099147;
        longitude = 29.157879;
      } else if (regionCode == "500116000000") {
        //江津
        latitude = 106.264435;
        longitude = 29.319984;
      } else if (regionCode == "500233000000") {
        //忠县
        latitude = 108.038073;
        longitude = 30.299817;
      } else if (regionCode == "500235000000") {
        //云阳
        latitude = 108.6975;
        longitude = 30.930628;
      } else if (regionCode == "500230000000") {
        //丰都
        latitude = 107.731056;
        longitude = 29.863785;
      } else if (regionCode == "500242000000") {
        //"酉阳土家族苗族自治县"
        latitude = 108.76726;
        longitude = 28.841409;
      } else if (this.regionCode == "500151000000") {
        //铜梁
        latitude = 106.056265;
        longitude = 29.845248;
      } else if (regionCode == "500114000000") {
        //黔江
        latitude = 108.770677;
        longitude = 29.533609;
      } else {
        //重庆
        latitude = 106.540603;
        longitude = 29.402348;
      }
      let _this = this;
      this.loading = true;
      try {
        let res = await overlayApi.rjtRegion(params);
        let list = res.data.list;
        this.loading = false;
        var map = new AMap.Map("container", {
          mapStyle: "amap://styles/darkblue",
          zoom: 10, //设置地图显示的缩放级别
          center: [latitude, longitude], //设置地图中心点坐标
          // layers: [new AMap.TileLayer.Satellite()], //设置图层,可设置成包含一个或多个图层的数组
          // mapStyle: "amap://styles/whitesmoke", //设置地图的显示样式
          viewMode: "2D", //设置地图模式
          fillColor: ["#FF0000", "#00FF00"], // 从红色渐变到绿色
        });
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          let { gpsLatitude, gpsLongitude, regionName } = element;
          // <p class="maptp">${this.address}</p>
          // 点标记显示内容，HTML要素字符串
          let markerContent = `<div id="clickOn" class="custom-content-marker">
           <img src="${this.imgurl}">
           <p>${regionName}</p>
          </div>`;
          // map.add(map);
          let yjp = [gpsLatitude, gpsLongitude];
          // 创建标注点
          let marker = new AMap.Marker({
            position: yjp, // 标注点位置坐标
            content: markerContent,
            // 以 icon 的 [center bottom] 为原点
            offset: new AMap.Pixel(-13, -30),
          });
          if (element.regionLevel < 4) {
            marker.on("click", function () {
              console.log("element is", element);
              _this.level = element.regionLevel;
              _this.code = element.region;
              _this.initMap();
              _this.humanList();
              _this.getmeshUserTree();
              _this.fullRegionName = element.fullRegionName;
            });
          } else if (element.regionLevel == 4) {
            marker.on("click", function () {
              _this.level = element.regionLevel;
              _this.code = element.region;
              _this.fullRegionName = element.fullRegionName;
              _this.humanList();
              _this.getmeshUserTree();
            });
          }
          // 将标注点添加到地图上
          map.add(marker);
        }
        map.setZoom(10);
        map.setCenter(latitude, longitude);
        console.log(latitude, longitude);
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    backHand() {
      this.level = 0;
      this.code = 0;
      this.personalInfo = null;
      this.isshuxinShow = true;
      this.initMap();
      this.humanList();
      this.getmeshUserTree();
      this.fullRegionName = this.userInfo.meshUser.regionName;
    },
  },
};
</script>

<style scoped lang="scss">
.pop-mina-con {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .title {
    position: absolute;
    top: 28px;
    left: 85px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #29eaf3;
  }

  .chart-con {
    position: absolute;
    left: 50%;
    top: 122px;
    transform: translateX(-50%);
    width: 99%;
    height: 800px;
    .mapin {
      width: 100%;
      height: 100%;
      ::v-deep .amap-container {
        background-color: rgba($color: #1a3566, $alpha: 0.12);
      }
    }
  }
  .chart-from-r {
    position: absolute;
    right: 8px;
    bottom: 61px;
    width: 665px;
    height: 729px;
    .top-con {
      width: 100%;
      height: 100%;
      background-color: rgba($color: #1a3566, $alpha: 0.85);
      border-radius: 20px;
      img {
        width: 100%;
        height: 43px;
      }
    }
  }
  .chart-from-l {
    position: absolute;
    left: 8px;
    bottom: 61px;
    width: 600px;
    height: 729px;
    .top-con {
      width: 100%;
      height: 100%;
      background-color: rgba($color: #1a3566, $alpha: 0.85);
      border-radius: 20px;
      img {
        width: 100%;
        height: 43px;
      }
    }
    .wgh-con {
      width: 580px;
      height: 640px;
      .content-box {
        width: 100%;
        height: 100%;
      }
    }
  }
  .c-mian-con {
    padding: 20px 30px;
    box-sizing: border-box;
    width: 100%;
    .back {
      position: absolute;
      top: 20px;
      right: 35px;
      font-size: 18px;
      color: #fff;
    }
    .title-con {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 14px;

      span {
        color: #18efff;
        width: 32px;

        font-size: 16px;
        &:nth-child(1) {
          margin-right: 56px;
          margin-left: 10px;
        }
        &:nth-child(2) {
          margin-right: 110px;
        }
        &:nth-child(3) {
          margin-right: 233px;
          width: 64px;
        }
        // &:nth-child(4) {
        //   margin-right: 235px;
        //   width: 64px;
        // }
        &:nth-child(4) {
          width: 64px;
        }
      }
    }
    ::v-deep .ant-progress-bg {
      height: 12px !important;
    }
    .current-con {
      margin-top: 19px;
      margin-bottom: 34px;
      span {
        &:nth-child(1) {
          color: #e6feff;
        }
        &:nth-child(2) {
          color: #1493fa;
        }
      }
    }
    .personnel-info {
      height: 550px;
      overflow-y: auto;
      .main-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        img {
          width: 170px;
          height: 200px;
          margin-right: 20px;
        }
        .item-inf {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          line-height: 60px;
          h1 {
            font-size: 20px;
            color: #fff;
          }
        }
      }
      .other-info {
        margin-top: 20px;
        line-height: 40px;
        h1 {
          font-size: 20px;
          color: #fff;
        }
      }
    }
    .personnel-info::-webkit-scrollbar {
      display: none;
    }
    .list-con {
      margin-top: 20px;
      width: 100%;
      height: 500px;
      overflow-y: auto;

      // .swiper-container {
      //   position: relative;
      //   height: 100%;
      //   .swiper-slide {
      //     position: relative;
      //     width: 100%;
      //   }
      // }
      .info-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 56px;
        margin-bottom: 15px;
        img {
          width: 44px;
          height: 53px;
          margin-right: 32px;
        }
        span {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #f7fefe;
        }
        .nickName {
          width: 60px;
          margin-right: 30px;

          margin-left: 10px;
        }
        .supervisePost {
          width: 110px;
          margin-right: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .pointName {
          width: 273px;
          margin-right: 18px;
          color: #29eaf3;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .showPhone {
          margin-right: 18px;
        }
        &:nth-child(odd) {
          background: url(../../assets/images/zushi.png);
          background-size: cover;
        }
        &:nth-child(even) {
          background: url(../../assets/images/zuy.png);
          background-size: cover;
        }
      }
    }
    .list-con::-webkit-scrollbar {
      display: none;
    }
  }
  .close {
    position: absolute;
    right: 40px;
    top: 83px;

    img {
      width: 30px;
      height: 30px;
    }
  }
  ::v-deep .custom-content-marker {
    @include flrowjusali();
    white-space: nowrap;
  }
}
</style>
