<template>
  <div>
    <div v-if="dashboardList && dashboardList.length != 0" class="dayins-list">
      <div class="list-con" v-for="(item, index) in dashboardList" :key="index">
        <div id="myDiv" class="item-con" :title="`检测合格率${item.passRate}%`">
          <a-progress
            type="dashboard"
            :stroke-color="{
              '0%': item.from,
              '100%': item.to,
            }"
            :percent="item.percent"
            :width="70"
            :strokeWidth="8"
          >
            <template #format="percent">
              <span style="color: #c1dde5; font-size: 20px"
                >{{ percent }}%</span
              >
            </template>
          </a-progress>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div v-else class="available">
      <a-empty />
    </div>
  </div>
</template>

<script>
import { Tooltip, Progress } from "ant-design-vue";
export default {
  components: {
    "a-progress": Progress,
  },
  props: {
    dashboardList: Array,
    print: Number,
  },
  data() {
    return {};
  },

  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.dayins-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 35px;
  width: 100%;
  .list-con {
    width: 12.5%;
    margin-bottom: 43px;
  }
  .item-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #cde2ff;
      margin-top: 10px;
      width: 65px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  .sheets-con {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -30%);
    display: none;
    padding: 20px;
    z-index: 999;
    background-color: rgba($color: #1a3566, $alpha: 0.5);
    border-radius: 10px;
    line-height: 40px;
    h1 {
      font-size: 18px;
      color: #fff;
    }
  }
  .item-con:hover .sheets-con {
    display: block;
  }
}
.available {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
</style>
